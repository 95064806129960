import React, { useState, useEffect } from "react";
import Nav from './nav';
import {Helmet} from "react-helmet";

import '../css/settings.css';

import { statusHandler } from './frontendUtils';

function Settings() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [userId, setUserId] = useState({});
    const [userEmail, setUserEmail] = useState({});
    const [userFirstName, setUserFirstName] = useState({});
    const [userLastName, setUserLastName] = useState({});

    const [hasTeamworkCredentials, setHasTeamworkCredentials] = useState(false);
    const [hasGoogleCredentials, setHasGoogleCredentials] = useState(false);
    const [teamworkAPIKey, setTeamworkAPIKey] = useState('');
    const [googleAPIKey, setGoogleAPIKey] = useState('');
    const [showTeamworkAPI, setShowTeamworkAPI] = useState(false);
    const [showGoogleAPI, setShowGoogleAPI] = useState(false);

    const [selectedSettings, setSelectedSettings] = useState(parseInt(sessionStorage.getItem('selectedSettings')) || 0);

    const get_user = async () => {
        fetch('/api/get_user', {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                window.location.href = "/login";
            }
        })
        .then(data => {
            if (data['user_id']) {
                // console.log(data)
                setLoggedIn(true);
                setUserId(data['user_id']);
                setUserEmail(data['user_email']);
                setUserFirstName(data['user_firstname']);
                setUserLastName(data['user_lastname']);
            }
            else {
                window.location.href = "/login";
            }
        })
        .catch(error => {
            console.log("[GET USER] " + error);
        });
    }

    const GetTeamworkApiKey = async () => {
        fetch('/api/teamwork_api', { 
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[TEAMWORK GET API] Error getting Teamwork API key");
            }
        })
        .then(data => {
            if (data.api_key) {  
                setTeamworkAPIKey(data.api_key);
            }
        })
        .catch(error => {
            console.log("[TEAMWORK GET API] " + error);
        });
    }

    const SaveTeamworkApiKey = async () => {
        if (document.getElementById("teamwork_api_input").value === "") {
            return;
        }

        fetch('/api/teamwork_api', { 
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ api_key: document.getElementById("teamwork_api_input").value })
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data);
            setHasTeamworkCredentials(true);
        })
        .catch(error => {
            console.log("[TEAMWORK SAVE API] " + error);
        });
    }

    const CheckCredentials = async () => {
        fetch('/api/check_credentials', { 
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(response => response.json())
        .then(data => {
            if (data['googleCredentials']) {
                setHasGoogleCredentials(true);
            }
            if (data['teamworkCredentials']) {
                setHasTeamworkCredentials(true);
            }
        })
        .catch(error => {
            console.log("[CREDENTIALS] " + error);
        });
    }

    const GoogleCredentials = async () => {
        fetch('/api/google_credentials', { 
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        })
        .then(response => response.json())
        .then(data => {
            window.open(data.authorization_url, "_self");
            // setHasGoogleCredentials(true);
        })
        .catch(error => {
            console.log("[GOOGLE CREDENTIALS] " + error);
        });
    }

    const getGoogleCredentialsApiKey = async () => {
        fetch('/api/google_credentials', {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[GOOGLE GET API] Error getting Google API key");
            }
        })
        .then(data => {
            if (data) {
                const credentials = JSON.parse(data.credentials);
                // console.log(credentials);
                setGoogleAPIKey(credentials["token"]);
            }
        })
        .catch(error => {
            console.log("[GOOGLE GET API] " + error);
        });
    }

    const resetAPIKey = async (type) => {
        fetch('/api/reset_api_key', { 
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ credentials_type: type })
        })
        .then(response => response.json())
        .then(data => {
            if (type === "teamwork") {
                document.getElementById("teamwork_api_input").value = "";
                setHasTeamworkCredentials(false);
            }
            else if (type === "google") {
                console.log("Reset Google API");
                setHasGoogleCredentials(false);
                setGoogleAPIKey("");
            }
        })
        .catch(error => {
            console.log("[CREDENTIALS RESET] " + error);
        });
    }

    const resetPassword = async (e) => {
        const status_handler = document.getElementById("password_change_status");
        if (password1.length > 0 && password2.length > 0 && password1 === password2)
        {
            e.preventDefault();

            fetch('/api/reset_password', { 
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ password: password1 })
            })
            .then(response => {
                if (response.status === 201) {
                    console.log("Password reset");
                    statusHandler(status_handler, "Wachtwoord is succesvol ingesteld!", "green", false);
                }
                else if (response.status === 400) {
                    console.log("Password not strong enough");
                    statusHandler(status_handler, "Wachtwoord niet sterk genoeg!", "red");
                }
                else {
                    console.log("Error resetting password");
                    statusHandler(status_handler, "Kon wachtwoord niet instellen! Probeer het opnieuw.", "red");
                }
            })
            .then(data => {
            })
            .catch(error => {
                console.log("[PASSWORD RESET] " + error);
            });
        }
        else
        {
            if (password1 === "" && password2 === "") {
                e.preventDefault();
                console.log("Empty password");
                statusHandler(status_handler, "Vul een wachtwoord in!", "red");
            }
            else {
                e.preventDefault();
                console.log("Passwords do not match");
                statusHandler(status_handler, "Wachtwoorden komen niet overeen!", "red");
            }
        }
    }

    useEffect(() => {
        get_user();
        GetTeamworkApiKey();
        getGoogleCredentialsApiKey();
        CheckCredentials();
    }, []);

    
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');


    useEffect(() => {
        sessionStorage.setItem("selectedSettings", selectedSettings);
    }, [selectedSettings]);

    return (
        <>
        {loggedIn && (
            <html>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Personal Assistant - Instellingen</title>
                </Helmet>
                <Nav />
                <body>
                    <div className="settings">
                        <div className="settings_holder">
                            <div className="account_frame">
                                <div className="frame_heading">
                                    <p className="heading4">Mijn account</p>
                                </div>
                                <div className="account_settings_frame">
                                    <button className="account_settings_button" onClick={e => setSelectedSettings(0)} disabled={selectedSettings === 0}>Gegevens</button>
                                    <button className="account_settings_button" onClick={e => setSelectedSettings(1)} disabled={selectedSettings === 1}>API sleutels</button>
                                </div>
                            </div>
                            <div className="settings_frame">
                                {selectedSettings === 0 && (
                                <>
                                    <p className="heading4">Gegevens</p>
                                    <form className="settings_body">
                                        <div className="input_field">
                                            <p className="input_caption">Naam</p>
                                            <div className="input_with_button">
                                                <input type="text" placeholder="Uw voor- en achternaam" className="settings_input" value={userFirstName + " " + userLastName} required={false} disabled={true}></input>
                                            </div>
                                        </div>
                                        <div className="input_field">
                                            <p className="input_caption">E-mailadres</p>
                                            <div className="input_with_button">
                                                <input type="email" placeholder="Uw e-mailadres" value={userEmail} className="settings_input" required={false} disabled={true}></input>
                                            </div>
                                        </div>
                                        <div className="input_field">
                                            <p className="input_caption">Wachtwoord</p>
                                            <p id="password_change_status" className="password_change_status"></p>
                                            <div className="input_with_button">
                                                <input type={showPassword1 === true ? "text" : "password"} placeholder="Nieuw wachtwoord" className="settings_input" value={password1} onChange={e => setPassword1(e.target.value)} required={true}></input>
                                                <button type="button" className="input_show_hide_button" onClick={e => setShowPassword1(!showPassword1)}>
                                                    {showPassword1 === true ? "Verbergen" : "Weergeven"}
                                                </button>
                                            </div>
                                            <div className="input_with_button">
                                                <input type={showPassword2 === true ? "text" : "password"}  placeholder="Herhaal wachtwoord" className="settings_input" value={password2} onChange={e => setPassword2(e.target.value)} required={true}></input>
                                                <button type="button" className="input_show_hide_button" onClick={e => setShowPassword2(!showPassword2)}>
                                                    {showPassword2 === true ? "Verbergen" : "Weergeven"}
                                                </button>
                                            </div>
                                        </div>
                                        <button type="submit" className="settings_button2" onClick={e => resetPassword(e)}>Opslaan</button>
                                    </form>
                                </>
                                )}
                                {selectedSettings === 1 && (
                                <>
                                    <p className="heading4">
                                        API sleutels
                                    </p>
                                    <div className="settings_body">
                                        <div className="input_field">
                                            <p className="input_caption">Google</p>
                                            <div className="input_with_button">
                                                <input type={showGoogleAPI === true ? "text" : "password"} placeholder="API sleutel" className="settings_input" value={googleAPIKey} required={false} disabled={true}/>
                                                <button type="button" className="input_show_hide_button" onClick={e => setShowGoogleAPI(!showGoogleAPI)}>{showGoogleAPI === true ? "Verbergen" : "Weergeven"}</button>
                                            </div>
                                            <div className="settings_buttons_holder">
                                                <button type="button" className="settings_button" onClick={e => GoogleCredentials()} disabled={hasGoogleCredentials}>Verlenen</button>
                                                <button type="button" className="settings_button" onClick={e => resetAPIKey("google")} disabled={!hasGoogleCredentials}>Verwijderen</button>
                                            </div>
                                        </div>
                                        <div className="input_field">
                                            <p className="input_caption">Teamwork</p>
                                            <div className="input_with_button">
                                                <input type={showTeamworkAPI === true ? "text" : "password"} id="teamwork_api_input"  placeholder="API sleutel" className="settings_input" value={teamworkAPIKey} onChange={e => setTeamworkAPIKey(e.target.value)} required={true} disabled={hasTeamworkCredentials}/>
                                                <button type="button" className="input_show_hide_button" onClick={e => setShowTeamworkAPI(!showTeamworkAPI)}>{showTeamworkAPI === true ? "Verbergen" : "Weergeven"}</button>
                                            </div>
                                            <div className="settings_buttons_holder">
                                                <button type="button" className="settings_button" onClick={e => SaveTeamworkApiKey()} disabled={hasTeamworkCredentials}>Opslaan</button>
                                                <button type="button" className="settings_button" onClick={e => resetAPIKey("teamwork")} disabled={!hasTeamworkCredentials}>Verwijderen</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                )}
                            </div>
                        </div>
                    </div>
                </body>
            </html>
        )}
        </>
    );
}
    
export default Settings;