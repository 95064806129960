import React, { useState, useEffect } from "react";

import '../css/logout.css';

import { setLoggedIn } from './frontendUtils';
import Nav from './nav';

function LoggedOut() {
    const redirect = async () => {
        setLoggedIn(false);
        window.location.href = "/login";
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            redirect();
        }, 2500);

        return () => clearTimeout(timer);
    }, []);

    return (
        <html>
            <body>
                <Nav />
                <div className="frame_holder">
                    <div className="frame">
                        <p className="heading4">U bent uitgelogd!</p>
                        <p className="logged_out_text">U wordt automatisch doorgeleid naar het loginscherm</p>
                        <button className="logged_out_button" onClick={redirect}>Klik hier om terug te gaan</button>
                    </div>
                </div>
            </body>
        </html>
    );
}

export default LoggedOut;