import React, { useState, useEffect } from "react";

import '../css/createMilestoneModal.css';
import '../css/main.css';

import Cancel from '../images/Cancel.png';

import VerifyModal from "./verifyModal";

import { statusHandler } from './frontendUtils';

function CreateMilestoneModal() {
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [privateMilestone, setPrivate] = useState(false);
    const [reminder, setReminder] = useState(false);
    const [deadline, setDeadline] = useState("");
    const [peopleData, setPeopleData] = useState([]);
    const [selectedPeople, setSelectedPeople] = useState([]);
    const [projectTaskLists, setProjectTaskLists] = useState([]);
    const [selectedTasklist, setSelectedTasklist] = useState("");


    const [selectedProjectId, setSelectedProjectId] = useState("");
    const [teamworkProjectsData, setTeamworkProjectsData] = useState([]);

    const [verifyModalType, setVerifyModalType] = useState("save");
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const handleCheckboxChange3 = (e, tag) => {
        if (e.target.checked === true) {
            if (!selectedTags.includes(tag)) {
                setSelectedTags([...selectedTags, tag]);
            }
        } else {
            setSelectedTags(selectedTags.filter(p => p !== tag));
        }
    }

    const GrabAllTags = async () => {
        fetch('/api/teamwork/tags', {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[ALL TAGS] Error getting all tags");
            }
        })
        .then(data => {
            setTags(data['tags']);
        })
        .catch(error => {
            console.log("[ALL TAGS] " + error);
        });
    }

    const handleConfirmation = (response) => {
        document.getElementById("CreateMilestoneModal").style.overflow = "auto";
        if (response === true) {
            if (verifyModalType === "create") {
                createMilestone();
            }
        }
    };

    const GrabTeamworkProjects = async () => {
        fetch('/api/teamwork/projects', {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(response => response.json())
        .then(data => {
            if (data["projects"])
            {
                setTeamworkProjectsData(data["projects"]);
            }
        })
        .catch(error => {
            console.log("[TEAMWORK GRAB API] " + error);
        });
    }

    const GrabPeopleInfo = async (project_id) => {
        if (project_id) {
            fetch('/api/teamwork/project', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    'project-id': project_id
                })
            })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else
                {
                    console.log("[PEOPLE INFO] Error getting people info");
                }
            })
            .then(data => {
                // console.log(data);
                setPeopleData(data['people']);
                // console.log(data['people']);
            })
            .catch(error => {
                console.log("[PEOPLE INFO] " + error);
            });
        }
    }

    const GrabProjectTasklists = async (project_id) => {
        if (project_id) {
            fetch('/api/teamwork/project_task_list', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ project_id: project_id })
            })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else
                {
                    console.log("[PROJECT TASKLISTS] Error getting project tasklists");
                }
            })
            .then(data => {
                setProjectTaskLists(data['tasklists']);
            })
            .catch(error => {
                console.log("[PROJECT TASKLISTS] " + error);
            });
        }
    }

    const handleCheckboxChange1 = (e, person) => {
        if (e.target.checked === true) {
            if (!selectedPeople.includes(person)) {
                setSelectedPeople([...selectedPeople, person]);
            }
        } else {
            setSelectedPeople(selectedPeople.filter(p => p !== person));
        }
    }

    const handleCheckboxChange2 = (e, tasklist) => {
        if (e.target.checked === true) {
            if (!selectedTasklist.includes(tasklist)) {
                setSelectedTasklist([...selectedTasklist, tasklist]);
            }
        } else {
            setSelectedTasklist(selectedTasklist.filter(p => p !== tasklist));
        }
    }

    const createMilestone = async (button) => {
        const e = document.getElementById("create_milestone_status_handler");
        
        if (title === "" || deadline === "" || selectedProjectId === "" || selectedPeople.length === 0) {
            
            if (selectedPeople.length === 0) {
                statusHandler(e, "Selecteer minimaal 1 persoon!", "red");
            }

            if (deadline === "") {
                statusHandler(e, "Vul een deadline in!", "red");
            }

            if (selectedProjectId === "") {
                statusHandler(e, "Selecteer een project!", "red");
            }

            if (title === "") {
                statusHandler(e, "Vul een titel in!", "red");
            }

            return;
        }
        fetch('/api/teamwork/create_milestone', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                project_id: selectedProjectId,
                milestone_title: title,
                milestone_description: description,
                milestone_reminder: reminder,
                milestone_deadline: deadline,
                milestone_private: privateMilestone,
                milestone_responsible_party_ids: selectedPeople.join(','),
                milestone_tasklist_ids: selectedTasklist,
                milestone_tag_ids: selectedTags.join(',')
            })
        })
        .then(response => {
            if (response.status === 201) {
                statusHandler(e, "Mijlpaal is aangemaakt!", "green", false);
            }
            else
            {
                statusHandler(e, "Mijlpaal kon niet aangemaakt worden!", "red");
            }
        })
        .catch(error => {
            console.log("[CREATE MILESTONE] " + error);
            statusHandler(e, "Mijlpaal kon niet aangemaakt worden!", "red");
        });
    }

    function closeModal(e) {
        if (!e || e.id === "CreateMilestoneModal") {
            const modal = document.getElementById("CreateMilestoneModal");
            modal.classList.add("disappear");

            document.body.style.overflow = "auto";

            modal.addEventListener('animationend', (e) => {
                if (e.animationName === 'disappear' && e.target.id === "CreateMilestoneModal") {
                    modal.style.display = "none";
                    modal.style.visibility = "hidden";
                    modal.classList.remove('disappear');
                }
            });

            setDescription("");
            setTitle("");
            setPrivate(false);
            setReminder(false);
            setDeadline("");
            setSelectedPeople([]);
            setSelectedTasklist([]);
            setSelectedProjectId("");
            setPeopleData([]);
            setProjectTaskLists([]);
            setSelectedTags([]);
        }
    }
    
    useEffect(() => {
        GrabTeamworkProjects();

        const teamworkProjectsInterval = setInterval(GrabTeamworkProjects, 15000);

        return () => {
            clearInterval(teamworkProjectsInterval);
        };
    }, []);

    useEffect(() => {
        GrabPeopleInfo(selectedProjectId);
        GrabProjectTasklists(selectedProjectId);
        setSelectedPeople([]);
        setSelectedTasklist([]);
        setSelectedTags([]);
        GrabAllTags();
    }, [selectedProjectId]);

    function openVerifyModal(type) {
        setVerifyModalType(type);
        const modal = document.getElementById("verifyCreateMilestoneModal");
        modal.style.display = 'flex';
        modal.style.visibility = 'visible';
        modal.classList.add("appear");

        if (document.getElementById("CreateMilestoneModal")) {
            modal.scrollIntoView({ behavior: "instant", block: "center" });
            document.getElementById("CreateMilestoneModal").style.overflow = "hidden";
        }

        modal.addEventListener('animationend', (e) => {
            if (e.animationName === 'appear' && e.target.id === modal.id) {
                modal.classList.remove('appear');
            }
        });
    }

    return (
        <>
            <div id="CreateMilestoneModal" className="CreateModal" onClick={e => closeModal(e.target)}>
                <VerifyModal handleConfirmation={handleConfirmation} type={verifyModalType} id="verifyCreateMilestoneModal"/>
                <div className="CreateModal_holder">
                    <div className="modal_header">
                        <p className="heading4">Mijlpaal aanmaken</p>
                        <img src={Cancel} alt="cancelImg" className="close_modal_button" onClick={e => closeModal()}></img>
                    </div>
                    <div className="modal_body">
                        <div className="modal_side">
                            <div className="modal_topside">
                                <div className="modal_textarea_holder modal_topside_left">
                                    <p>Beschrijving</p>
                                    <textarea className="modal_topside_textarea" value={description} placeholder="Beschrijving van mijlpaal" onChange={e => setDescription(e.target.value)}></textarea>
                                </div>
                                <div className="modal_topside_right">
                                    <div className="modal_item">
                                        <p>Titel</p>
                                        <input type="text" className="modal_input" placeholder="Titel van mijlpaal" value={title} onChange={e => setTitle(e.target.value)} required={true}></input>
                                    </div>
                                    <div className="modal_item">
                                        <div className="switch_holder">
                                            <label className="switch">
                                                <input type="checkbox" checked={privateMilestone} onChange={e => setPrivate(e.target.checked)}></input>
                                                <span className="slider round"></span>
                                            </label>
                                            <p className="list_item_text">Afgeschermd</p>
                                        </div>  
                                        <div className="switch_holder">
                                            <label className="switch">
                                                <input type="checkbox" checked={reminder} onChange={e => setReminder(e.target.checked)}></input>
                                                <span className="slider round"></span>
                                            </label>
                                            <p className="list_item_text">Herinnering</p>
                                        </div>
                                    </div>
                                    <div className="modal_item">
                                        <p>Deadline</p>
                                        <input type="date" className="modal_datepicker" value={deadline} onChange={e => setDeadline(e.target.value)} required={true}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="milestoneModal_lists_holder">
                                <div className="modal_list_holder">
                                    <p>Projecten</p>
                                    <div className="list_body milestoneModal_list">
                                        {teamworkProjectsData.length > 0 ? (
                                            teamworkProjectsData.map((project, index) =>
                                                <div className="switch_holder" key={index}>
                                                    <label className="switch">
                                                        <input type="checkbox" onChange={e => setSelectedProjectId(project['id'])} checked={selectedProjectId === project['id']}></input>
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <p className="switch_text">{project['name']}</p>
                                                </div>
                                            )
                                        )
                                        :
                                        (
                                            <p>Geen projecten beschikbaar</p>
                                        )}
                                    </div>
                                </div>
                                <div className="modal_list_holder">
                                    <p>Lijst</p>
                                    <div className="list_body milestoneModal_list">
                                        {projectTaskLists.length > 0 ? (
                                            projectTaskLists.map((tasklist, index) =>
                                                <div className="switch_holder" key={index}>
                                                    <label className="switch">
                                                        <input type="checkbox" onChange={e => handleCheckboxChange2(e, tasklist['id'])} checked={selectedTasklist.includes(tasklist['id'])}></input>
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <p className="switch_text">{tasklist['name']}</p>
                                                </div>
                                            )
                                        )
                                        :
                                        (
                                            <p>Geen lijsten beschikbaar</p>
                                        )}
                                    </div>
                                </div>
                                <div className="modal_list_holder">
                                    <p>Verantwoordelijk</p>
                                    <div className="list_body milestoneModal_list">
                                        {peopleData.length > 0 ? (
                                            peopleData.map((person, index) =>
                                                <div className="switch_holder" key={index}>
                                                    <label className="switch">
                                                        <input type="checkbox" onChange={e => handleCheckboxChange1(e, person["id"])} checked={selectedPeople.includes(person["id"])}></input>
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <p className="switch_text">{person['firstName']} {person['lastName']}</p>
                                                </div>
                                            )
                                        )
                                        :
                                        (
                                            <p>Niemand beschikbaar</p>
                                        )}
                                    </div>
                                </div>
                                <div class="modal_list_holder">
                                    <p>Tags</p>
                                    <div class="list_body milestoneModal_list">
                                        {tags.length > 0 ? (
                                            tags.map((tag, index) =>
                                                <div class="switch_holder" key={index}>
                                                    <label class="switch">
                                                        <input type="checkbox" onChange={e => handleCheckboxChange3(e, tag['id'])} checked={selectedTags.includes(tag["id"])}></input>
                                                        <span class="slider round"></span>
                                                    </label>
                                                    <p class="switch_text tags" style={{backgroundColor: tag['color'], color: "white"}}>{tag['name']}</p>
                                                </div>
                                            )
                                        )
                                        :
                                        (
                                            <p>Geen tags beschikbaar</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal_bottom_buttons">
                                <div id="create_milestone_status_handler" className="modal_status_handler"></div>
                                <div className="modal_bottom_buttons">
                                    <button className="frame_button" onClick={() => openVerifyModal("create")}>Aanmaken</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateMilestoneModal;
