import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";

import Nav from './nav';

import '../css/404.css';
import '../css/main.css';

function ErrorPage() {
    const redirect = async () => {
        window.location.href = "/login";
    }

    return (
        <>
            <html>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Personal Assistant - 404</title>
                </Helmet>
                <Nav />
                <body>
                    <div class="frame_holder">
                        <div class="frame">
                            <p class="heading4">404</p>
                            <p class="error_text">Oeps.. Uw assistent is u verloren!</p>
                            <p class="error_text">Ga snel terug om uw assistent op rust te stellen.</p>
                            <button class="back_button" onClick={redirect}>Klik hier om naar het loginscherm te gaan.</button>
                        </div>
                    </div>
                </body>
            </html>
        </>        
    );
}

export default ErrorPage;