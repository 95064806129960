import React, { useState, useEffect } from "react";

import { dateTimeToFullText } from '../templates/frontendUtils';

import Cancel from '../images/Cancel.png';

import "../css/timeboxEventModal.css";
import "../css/main.css";

function TimeboxEventModal({ handleConfirmation, selectedTimeboxEvent }) {
    const handleButtonClick = (response) => {
        handleConfirmation(response, selectedTimeboxEvent);
        closeModal();
    };

    function closeModal(e) {
        if (!e || e.id === "timeboxEventModal") {
            if (document.getElementById("timeboxEventModal")) {
                const modal = document.getElementById("timeboxEventModal");
                modal.classList.add("disappear");

                modal.addEventListener('animationend', (e) => {
                    if (e.animationName === 'disappear' && e.target.id === "timeboxEventModal") {
                        modal.style.display = "none";
                        modal.style.visibility = "hidden";
                        modal.classList.remove('disappear');
                    }
                });
                handleConfirmation("", "");
            }
        }
    }
    
    return (
        <>
            <div id="timeboxEventModal" className="timeboxEventModal_holder" onClick={e => closeModal(e.target)}>
                <div className="timeboxEventModal">
                    <div className="modal_header">
                        <p className="heading4">Afspraak opties</p>
                        <img src={Cancel} alt="cancelImg" className="close_modal_button" onClick={e => closeModal()}></img>
                    </div>
                    <div className="modal_body">
                        <div className="modal_side">
                            <input type="text" className="modal_input" value={selectedTimeboxEvent?._def["title"]} disabled></input>
                            <input type="text" className="modal_input" value={dateTimeToFullText(selectedTimeboxEvent?._instance?.range["start"].toISOString())} disabled></input>
                            <input type="text" className="modal_input" value={dateTimeToFullText(selectedTimeboxEvent?._instance?.range["end"].toUTCString())} disabled></input>
                        {/* </div>
                        <div className="modal_side"> */}
                            <button onClick={() => handleButtonClick("delete")} className="confirm_button cancel">Verwijderen</button>
                            <button onClick={() => handleButtonClick("add")} className="confirm_button">Zet in Teamwork kalender</button>
                            <button onClick={() => handleButtonClick("redirect")} className="confirm_button accept">Ga naar Teamwork kalender</button>
                        </div>
                    </div>
                </div>
            </div>
        </>    
    );
}

export default TimeboxEventModal;