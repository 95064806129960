import React, { useState, useEffect } from "react";

import '../css/eventModal.css';

import Cancel from '../images/Cancel.png';

import VerifyModal from "./verifyModal";

import { dateTimeToFullText, statusHandler } from './frontendUtils';

function EventModal({ selectedEvent }) {
    const [summary, setSummary] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    const [status, setStatus] = useState('confirmed');
    const [visibility, setVisibility] = useState('default');

    const [verifyModalType, setVerifyModalType] = useState("save");

    const handleConfirmation = (response) => {
        document.getElementsByClassName("eventModal")[0].style.overflow = "auto";
        if (response === true) {
            if (verifyModalType === "delete") {
                deleteEvent();
            }
            else if (verifyModalType === "save") {
                saveSelectedEvent();
            }
        }
    };

    const dataToTimeDate = (dateTimeString, value) => {
        if (dateTimeString) {
            const dateTime = new Date(dateTimeString);

            let dateOutput, timeOutput;

            if (dateTimeString.includes("T")) {
                const year = dateTime.getFullYear();
                const month = String(dateTime.getMonth() + 1).padStart(2, '0');
                const day = String(dateTime.getDate()).padStart(2, '0');

                const hours = String(dateTime.getHours()).padStart(2, '0');
                const minutes = String(dateTime.getMinutes()).padStart(2, '0');

                dateOutput = year + "-" + month + "-" + day;
                timeOutput = hours + ":" + minutes;
            } else {
                const year = dateTime.getFullYear();
                const month = String(dateTime.getMonth() + 1).padStart(2, '0');
                const day = String(dateTime.getDate()).padStart(2, '0');

                dateOutput = year + "-" + month + "-" + day;
                timeOutput = "00:00";
            }

            if (value === "start") {
                setStartDate(dateOutput);
                setStartTime(timeOutput);
            }
            else if (value === "end") {
                setEndDate(dateOutput);
                setEndTime(timeOutput);
            }
        }
    }

    const saveSelectedEvent = async () => {
        const e = document.getElementById("event_status_handler");
        fetch('/api/google/update_event', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify({ 
                'calendarId': selectedEvent.calendar_id,
                'id': selectedEvent.id,
                'summary': summary,
                'description': description,
                'location': location,
                'startTime': startTime,
                'endTime': endTime,
                'startDate': startDate,
                'endDate': endDate,
                'status': status,
                'visibility': visibility
            })
        })
        .then(response => {
            if (response.status === 201) {
                statusHandler(e, "Afspraak is opgeslagen!", "green", false);
            }
            else
            {
                statusHandler(e, "Afspraak kon niet opgeslagen worden!", "red");
            }
        })
        .catch(error => {
            console.log("[SAVE EVENT] " + error);
            statusHandler(e, "Afspraak kon niet opgeslagen worden!", "red");
        });
    
    }

    const deleteEvent = async () => {
        const e = document.getElementById("event_status_handler");
        fetch('/api/google/delete_event', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify({
                'calendarId': selectedEvent.calendar_id,
                'id': selectedEvent.id
            })
        })
        .then(response => {
            if (response.status === 200) {
                statusHandler(e, "Afspraak is verwijderd!", "green", false);
            }
            else
            {
                statusHandler(e, "Afspraak kon niet verwijderd worden!", "red");
            }
        })
        .catch(error => {
            console.log("[DELETE EVENT] " + error);
            statusHandler(e, "Afspraak kon niet verwijderd worden!", "red");
        });
    }

    function redirectToGoogle() {
        console.log(selectedEvent.htmlLink);
        window.open(`${selectedEvent.htmlLink}`);
    }

    useEffect(() => {
        if (!selectedEvent) return;
        
        setSummary(selectedEvent.summary ? selectedEvent.summary : '');
        setDescription(selectedEvent.description ? selectedEvent.description : '');
        setLocation(selectedEvent.location ? selectedEvent.location : '');
        dataToTimeDate(selectedEvent.start?.dateTime ? selectedEvent.start?.dateTime : selectedEvent.start?.date, "start");
        dataToTimeDate(selectedEvent.end?.dateTime ? selectedEvent.end?.dateTime : selectedEvent.end?.date, "end");
        setStatus(selectedEvent.status ? selectedEvent.status : 'confirmed');
        setVisibility(selectedEvent.visibility ? selectedEvent.visibility : 'default');
    }, [selectedEvent]);

    function closeModal(e) {
        if (!e || e.id === "modal") {
            const modal = document.querySelector(".eventModal");
            modal.classList.add("disappear");

            document.body.style.overflow = "auto";

            modal.addEventListener('animationend', (e) => {
                if (e.animationName === 'disappear' && e.target.id === "modal") {
                    modal.style.display = "none";
                    modal.style.visibility = "hidden";
                    modal.classList.remove('disappear');
                }
            });
        }
    }

    function openVerifyModal(type) {
        setVerifyModalType(type);
        const modal = document.getElementById("verifyEventModal");
        modal.style.display = 'flex';
        modal.style.visibility = 'visible';
        modal.classList.add("appear");

        if (document.getElementsByClassName("eventModal")[0]) {
            modal.scrollIntoView({ behavior: "instant", block: "center" });
            document.getElementsByClassName("eventModal")[0].style.overflow = "hidden";
        }

        modal.addEventListener('animationend', (e) => {
            if (e.animationName === 'appear' && e.target.id === modal.id) {
                modal.classList.remove('appear');
            }
        });
    }

    return (
        <>
            {selectedEvent && (
                <div id="modal" className="eventModal" onClick={e => closeModal(e.target)}>
                    <VerifyModal handleConfirmation={handleConfirmation} type={verifyModalType} id="verifyEventModal"/>
                    <div className="eventModal_holder">
                        <div className="modal_header">
                            <p className="heading4">Afspraak gegevens</p>
                            <img src={Cancel} alt="cancelImg" className="close_modal_button" onClick={e => closeModal()}></img>
                        </div>
                        <div className="modal_body">
                            <div className="modal_side space_between">
                                <div className="modal_textarea_holder">
                                    <p>Beschrijving</p>
                                    <textarea className="modal_textarea" value={description} placeholder="Beschrijving van afspraak" onChange={e => setDescription(e.target.value)}></textarea>
                                </div>
                                <div className="modal_item">
                                    <p>Maker</p>
                                    <input type="text" className="modal_input" placeholder="Maker" value={selectedEvent.creator?.email || ''} disabled={true}></input>
                                </div>
                                <div className="modal_item">
                                    <p>Organizator</p>
                                    <input type="text" className="modal_input" placeholder="Orgnizator" value={selectedEvent.organizer?.email || ''} disabled={true}></input>
                                </div>
                                <div className="modal_item">
                                    <p>Aangemaakt</p>
                                    <input type="text" className="modal_input" placeholder="Orgnizator" value={dateTimeToFullText(selectedEvent.created) || ''} disabled={true}></input>
                                </div>
                                <div className="modal_item">
                                    <p>Laatste aanpassing</p>
                                    <input type="text" className="modal_input" placeholder="Laatste aanpassing" value={dateTimeToFullText(selectedEvent.updated) || ''} disabled={true}></input>
                                </div>
                            </div>
                            <div className="modal_side space_between">
                                <div className="modal_item">
                                    <p>Titel</p>
                                    <input type="text" className="modal_input" placeholder="Titel van afspraak" value={summary} onChange={e => setSummary(e.target.value)}></input>
                                </div>
                                <div className="modal_item">
                                    <p>Locatie</p>
                                    <input type="text" className="modal_input" placeholder="Locatie" value={location} onChange={e => setLocation(e.target.value)}></input>
                                </div>
                                <div className="modal_item_combo">
                                    <div className="modal_item">
                                        <p>Start datum</p>
                                        <input type="date" className="modal_datepicker" value={startDate} onChange={e => setStartDate(e.target.value)}></input>
                                    </div>
                                    <div className="modal_item">
                                        <p>End datum</p>
                                        <input type="date" className="modal_datepicker" value={endDate} onChange={e => setEndDate(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="modal_item_combo">
                                    <div className="modal_item">
                                        <p>Start Time</p>
                                        <input type="time" className="modal_timepicker" value={startTime} onChange={e => setStartTime(e.target.value)}></input>
                                    </div>
                                    <div className="modal_item">
                                        <p>End Time</p>
                                        <input type="time" className="modal_timepicker" value={endTime} onChange={e => setEndTime(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="modal_item">
                                    <p>Status</p>
                                    <select className="modal_select" value={status} onChange={e => setStatus(e.target.value)}>
                                        <option value="confirmed">Bevestigd</option>
                                        <option value="tentative">Voorlopig</option>
                                        <option value="cancelled">Geannuleerd</option>
                                    </select>
                                </div>
                                <div className="modal_item">
                                    <p>Privacy</p>
                                    <select className="modal_select" value={visibility} onChange={e => setVisibility(e.target.value)}>
                                        <option value="default">Standaard</option>
                                        <option value="public">Openbaar</option>
                                        <option value="private">Privé</option>
                                        <option value="confidential">Vertrouwelijk</option>
                                    </select>
                                </div>
                                <div className="modal_buttons_holder">
                                    <button className="modal_button_warning" onClick={e => openVerifyModal("delete")}>Verwijder afspraak</button>
                                    <button className="modal_button" onClick={e => redirectToGoogle()}>Ga naar afspraak</button>
                                </div>
                            </div>
                        </div>
                        <div className="modal_bottom_buttons">
                            <div id="event_status_handler" className="modal_status_handler"></div>
                            <button className="frame_button" onClick={() => openVerifyModal("save")}>Opslaan</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default EventModal;
