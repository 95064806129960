import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";

import '../css/dashboard.css';
import '../css/main.css';

import Nav from './nav';
import pencilImg from '../images/Pencil.png';
import checkmarkImg from '../images/Check mark.png';
import checkedmarkImg from '../images/Checked mark.png';
import addImg from '../images/Add.png';
import filterImg from '../images/Filter.png';
import hourglassImg from '../images/Hourglass.png';
import milestoneImg from '../images/Milestone.png';

import MilestoneModal from "./milestoneModal";
import TaskModal from "./taskModal";
import EventModal from "./eventModal";
import CreateEventModal from "./createEventModal";
import CreateMilestoneModal from "./createMilestoneModal";
import CreateTaskModal from "./createTaskModal";
// import TasklistViewer from "./tasklistViewer";

import { get_user, getLoggedIn, formatDateTeamwork, formatGoogleDate, formatGoogleDateTime, formatLocation, formatTime, getTodayDate, getTodayTime } from './frontendUtils';

import  { dateToText, googleEventsDateToText, googleEventsDateTimeToText } from './frontendUtils';

function Dashboard() {
    const [googleEventsData, setGoogleEventsData] = useState([]);
    const [teamworkTasksData, setTeamworkTasksData] = useState([]);
    const [teamworkFlawsData, setTeamworkFlawsData] = useState([]);
    // const [teamworkTasklistsData, setTeamworkTasklistsData] = useState([]);
    const [teamworkMilestonesData, setTeamworkMilestonesData] = useState([]);
    const [teamworkProjectsData, setTeamworkProjectsData] = useState([]);

    const [selectedTask, setSelectedTask] = useState({});
    const [selectedEvent, setSelectedEvent] = useState({});
    // const [selectedTasklist, setSelectedTasklist] = useState({});
    const [selectedMilestone, setSelectedMilestone] = useState({});
    const [selectedProjectTasks, setSelectedProjectTasks] = useState(0);
    const [selectedProjectTasklists, setSelectedProjectTasklists] = useState(0);
    const [selectedProjectMilestones, setSelectedProjectMilestones] = useState(0);

    const [completedTasksCount, setCompletedTasksCount] = useState(0);
    const [lateTasksCount, setLateTasksCount] = useState(0);
    const [activeProjectsCount, setActiveProjectsCount] = useState(0);
    const [summary, setSummary] = useState("No summary available");

    const [tasksViewLimit, setTasksViewLimit] = useState(20);
    const [tasksTotalViewLimit, setTasksTotalViewLimit] = useState(20);
    const [milestonesViewLimit, setMilestonesViewLimit] = useState(20);
    const [milestonesTotalViewLimit, setMilestonesTotalViewLimit] = useState(20);
    const [flawsViewLimit, setFlawsViewLimit] = useState(20);
    const [flawsTotalViewLimit, setFlawsTotalViewLimit] = useState(20);
    const [googleEventsViewLimit, setGoogleEventsViewLimit] = useState(20);
    const [googleEventsTotalViewLimit, setGoogleEventsTotalViewLimit] = useState(20);
    
    const [flawsFilterEnabled, setFlawsFilterEnabled] = useState(false);
    const [milestoneFilterEnabled, setMilestoneFilterEnabled] = useState(false);
    const [tasksFilterEnabled, setTasksFilterEnabled] = useState(false);
    const [eventsFilterEnabled, setEventsFilterEnabled] = useState(false);

    const [showAllTasks, setShowAllTasks] = useState(1);
    const [showCompletedTasks, setShowCompletedTasks] = useState(false);
    const [showDeletedTasks, setShowDeletedTasks] = useState(false);

    const [eventsStartDateFilter, setEventsStartDateFilter] = useState(getTodayDate);
    const [eventsEndDateFilter, setEventsEndDateFilter] = useState("");
    const [eventsStartTimeFilter, setEventsStartTimeFilter] = useState(getTodayTime);
    const [eventsEndTimeFilter, setEventsEndTimeFilter] = useState("00:00");

    const [flawType, setFlawType] = useState("all");

    const [clickedMarkTasks, setClickedMarkTasks] = useState([]);
    const [clickedMarkFlaws, setClickedMarkFlaws] = useState([]);

    const handleClickTasks = (index, task) => {
        if (task.completed === true) {
            setClickedMarkTasks(clickedMarkTasks.filter(item => item !== index));
            UncompleteTeamworkTask(task.id);
            task.completed = false;
        } else {
            setClickedMarkTasks([...clickedMarkTasks, index]);
            CompleteTeamworkTask(task.id);
            task.completed = true;
        }
    };

    const handleClickFlaws = (index, task) => {
        if (task.completed === true) {
            setClickedMarkFlaws(clickedMarkFlaws.filter(item => item !== index));
            UncompleteTeamworkTask(task.id);
            task.completed = false;
        } else {
            setClickedMarkFlaws([...clickedMarkFlaws, index]);
            CompleteTeamworkTask(task.id);
            task.completed = true;
        }
    };

    function showMore(type) {
        let updatedLimit;

        switch (type) {
            case "tasks":
                updatedLimit = tasksViewLimit + 20;
                setTasksViewLimit(updatedLimit > teamworkTasksData.length ? teamworkTasksData.length : updatedLimit);
                break;
            case "milestones":
                updatedLimit = milestonesViewLimit + 20;
                setMilestonesViewLimit(updatedLimit > teamworkMilestonesData.length ? teamworkMilestonesData.length : updatedLimit);
                break;
            case "flaws":
                updatedLimit = flawsViewLimit + 20;
                setFlawsViewLimit(updatedLimit > teamworkFlawsData.length ? teamworkFlawsData.length : updatedLimit);
                break;
            case "google_events":
                updatedLimit = googleEventsViewLimit + 20;
                setGoogleEventsViewLimit(updatedLimit > googleEventsData.length ? googleEventsData.length : updatedLimit);
                break;
            default:
                console.error("Unknown type:", type);
        }
    }

    const viewTask = (task, index) => {
        if (task) {
            setSelectedTask(task);
            if (document.querySelector(".taskModal")) {
                const modal = document.querySelector(".taskModal");
                modal.style.display = 'flex';
                modal.style.visibility = 'visible';
                modal.classList.add("appear");

                if (document.getElementById("dashboard")) {
                    document.body.style.overflow = "hidden";
                }
    
                modal.addEventListener('animationend', (e) => {
                    if (e.animationName === 'appear' && e.target.id === "taskModal") {
                        modal.classList.remove('appear');
                    }
                });
            }
        }
    }

    const viewEvent = (event, index) => {
        if (event) {
            setSelectedEvent(event);
            if (document.querySelector(".eventModal")) {
                const modal = document.querySelector(".eventModal");
                modal.style.display = 'flex';
                modal.style.visibility = 'visible';
                modal.classList.add("appear");

                if (document.getElementById("dashboard")) {
                    document.body.style.overflow = "hidden";
                }

                modal.addEventListener('animationend', (e) => {
                    if (e.animationName === 'appear' && e.target.id === "eventModal") {
                        modal.classList.remove('appear');
                    }
                });
            }
        }
    }

    const viewCreateEvent = () => {
        if (document.getElementById("CreateEventModal")) {
            const modal = document.getElementById("CreateEventModal");
            modal.style.display = 'flex';
            modal.style.visibility = 'visible';
            modal.classList.add("appear");

            if (document.getElementById("dashboard")) {
                document.body.style.overflow = "hidden";
            }

            modal.addEventListener('animationend', (e) => {
                if (e.animationName === 'appear' && e.target.id === "CreateEventModal") {
                    modal.classList.remove('appear');
                }
            });
        }
    }

    const viewCreateMilestone = () => {
        if (document.getElementById("CreateMilestoneModal")) {
            const modal = document.getElementById("CreateMilestoneModal");
            modal.style.display = 'flex';
            modal.style.visibility = 'visible';
            modal.classList.add("appear");

            if (document.getElementById("dashboard")) {
                document.body.style.overflow = "hidden";
            }
            
            modal.addEventListener('animationend', (e) => {
                if (e.animationName === 'appear' && e.target.id === "CreateMilestoneModal") {
                    modal.classList.remove('appear');
                }
            });
        }
    }

    const viewCreateTask = () => {
        if (document.getElementById("CreateTaskModal")) {
            const modal = document.getElementById("CreateTaskModal");
            modal.style.display = 'flex';
            modal.style.visibility = 'visible';
            modal.classList.add("appear");

            if (document.getElementById("dashboard")) {
                document.body.style.overflow = "hidden";
            }

            modal.addEventListener('animationend', (e) => {
                if (e.animationName === 'appear' && e.target.id === "CreateTaskModal") {
                    modal.classList.remove('appear');
                }
            });
        }
    }

    const viewMilestone = (milestone, index) => {
        if (milestone) {
            setSelectedMilestone(milestone);
            if (document.querySelector(".milestoneModal")) {
                const modal = document.querySelector(".milestoneModal");
                modal.style.display = 'flex';
                modal.style.visibility = 'visible';
                modal.classList.add("appear");

                if (document.getElementById("dashboard")) {
                    document.body.style.overflow = "hidden";
                }
    
                modal.addEventListener('animationend', (e) => {
                    if (e.animationName === 'appear' && e.target.id === "milestoneModal") {
                        modal.classList.remove('appear');
                    }
                });
            }
        }
    }

    // const viewTasklist = (tasklist, index) => {
    //     if (tasklist) {
    //         setSelectedTasklist(tasklist);
    //         document.getElementById("tasklistViewer").style.visibility = 'visible';
    //         // console.log(selectedTask);
    //     }
    // }

    const GrabGoogleEvents = async () => {
        fetch('/api/google/events', { 
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ startDate: eventsStartDateFilter, endDate: eventsEndDateFilter, startTime: eventsStartTimeFilter, endTime: eventsEndTimeFilter  })
        })
        .then(response => response.json())
        .then(data => {
            if (data) {
                setGoogleEventsData(data);
                setGoogleEventsTotalViewLimit(data.length);
                if (data.length < googleEventsViewLimit) {
                    setGoogleEventsViewLimit(data.length);
                }
            }
        })
        .catch(error => {
            console.log("[GOOGLE EVENTS GRAB API] " + error);
        });
    }

    const GrabTeamworkTasks = async () => {
        fetch('/api/teamwork/tasks', { 
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ projectId: selectedProjectTasks, showCompleted: showCompletedTasks, showMine: showAllTasks, showDeleted: showDeletedTasks })
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[TEAMWORK GRAB API] Error getting Teamwork tasks");
            }
        })
        .then(data => {
            // if (data) {
                // console.log(data);
                setTeamworkTasksData([]);
                setTeamworkTasksData(data);
                setTasksTotalViewLimit(data.length);
                if (data.length < tasksViewLimit) {
                    setTasksViewLimit(data.length);
                }
            // }
        })
        .catch(error => {
            console.log("[TEAMWORK GRAB API] " + error);
        });
    }

    const CompleteTeamworkTask = async (id) => {
        fetch('/api/teamwork/task/complete', { 
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ task_id: id })
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[TEAMWORK COMPLETE API] Error getting Teamwork tasks");
            }
        })
        .then(data => {
        //    teamworkTasksData.find(task => task.id === id).completed = true;
        //    teamworkFlawsData.find(task => task.id === id).completed = true;
        })
        .catch(error => {
            console.log("[TEAMWORK COMPLETE API] " + error);
        });
    }

    const UncompleteTeamworkTask = async (id) => {
        fetch('/api/teamwork/task/uncomplete', { 
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ task_id: id })
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[TEAMWORK COMPLETE API] Error getting Teamwork tasks");
            }
        })
        .then(data => {
        //    teamworkTasksData.find(task => task.id === id).completed = false;
        //    teamworkFlawsData.find(task => task.id === id).completed = false;
        })
        .catch(error => {
            console.log("[TEAMWORK COMPLETE API] " + error);
        });
    }

    // const GrabTeamworkTasksSpecific = async () => {
    //     fetch('/api/teamwork/project/tasks', {
    //         method: 'POST',
    //         credentials: 'include',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({ project_id: selectedProjectTasks })
    //     })
    //     .then(response => {
    //         if (response.status === 200) {
    //             return response.json();
    //         }
    //         else
    //         {
    //             console.log("[TEAMWORK GRAB API] Error getting Teamwork tasks");
    //         }
    //     })
    //     .then(data => {
    //         if (data['todo-items']) {
    //             setTeamworkTasksData(data['todo-items']);
    //             setTasksTotalViewLimit(data['todo-items'].length);
    //         }
    //     })
    //     .catch(error => {
    //         console.log("[TEAMWORK GRAB API] " + error);
    //     });
    // }

    const GrabTeamworkFlaws = async () => {
        // console.log(flawType);
        fetch('/api/teamwork/flawed_tasks', { 
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ flawType: flawType})
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[TEAMWORK GRAB API] Error getting Teamwork tasks");
            }
        })
        .then(data => {
            if (data) {
                // console.log("==================");
                // console.log(data);
                // console.log("==================");
                setTeamworkFlawsData(data);
                setFlawsTotalViewLimit(data.length);
            }
        })
        .catch(error => {
            console.log("[TEAMWORK GRAB API] " + error);
        });
    }

    // const GrabTeamworkTasklists = async () => {
    //     fetch('/api/teamwork/tasklists', {
    //         method: 'GET',
    //         credentials: 'include',
    //         headers: { 'Content-Type': 'application/json' },
    //     })
    //     .then(response => response.json())
    //     .then(data => {
    //         if (data["tasklists"])
    //         {
    //             setTeamworkTasklistsData(data["tasklists"]);
    //         }
    //     })
    //     .catch(error => {
    //         console.log("[TEAMWORK GRAB API] " + error);
    //     });
    // }

    // const GrabTeamworkTasklistsSpecific = async () => {
    //     fetch('/api/teamwork/tasklists', {
    //         method: 'POST',
    //         credentials: 'include',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({ project_id: selectedProjectTasklists })
    //     })
    //     .then(response => response.json())
    //     .then(data => {
    //         if (data["tasklists"])
    //         {
    //             setTeamworkTasklistsData(data["tasklists"]);
    //         }
    //     })
    //     .catch(error => {
    //         console.log("[TEAMWORK GRAB API] " + error);
    //     });
    // }

    const GrabTeamworkMilestones = async () => {
        fetch('/api/teamwork/milestones', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ project_id: selectedProjectMilestones })
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[TEAMWORK GRAB API] Error getting Teamwork milestones");
            }
        })
        .then(data => {
            if (data['milestones']) {
                setTeamworkMilestonesData(data['milestones']);
                setMilestonesTotalViewLimit(data['milestones'].length);
                if (data['milestones'].length < milestonesViewLimit) {
                    setMilestonesViewLimit(data['milestones'].length);
                }
            }
            else
            {
                setTeamworkMilestonesData([]);
                setMilestonesTotalViewLimit(0);
                setMilestonesViewLimit(0);
            }
        })
        .catch(error => {
            console.log("[TEAMWORK GRAB API] " + error);
        });
    }

    // const GrabTeamworkMilestonesSpecific = async () => {
    //     fetch('/api/teamwork/project/milestones', {
    //         method: 'POST',
    //         credentials: 'include',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({ project_id: selectedProjectMilestones })
    //     })
    //     .then(response => {
    //         if (response.status === 200) {
    //             return response.json();
    //         }
    //         else
    //         {
    //             console.log("[TEAMWORK GRAB API] Error getting Teamwork milestones");
    //         }
    //     })
    //     .then(data => {
    //         // console.log(data);
    //         if (data['milestones']) {
    //             setTeamworkMilestonesData(data['milestones']);
    //             setMilestonesTotalViewLimit(data['milestones'].length);
    //             if (data['milestones'].length < milestonesViewLimit) {
    //                 setMilestonesViewLimit(data['milestones'].length);
    //             }
    //         }
    //     })
    //     .catch(error => {
    //         console.log("[TEAMWORK GRAB API] " + error);
    //     });
    // }

    const GrabTeamworkProjects = async () => {
        fetch('/api/teamwork/projects', {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(response => response.json())
        .then(data => {
            if (data["projects"])
            {
                setTeamworkProjectsData(data["projects"]);
            }
        })
        .catch(error => {
            console.log("[TEAMWORK GRAB API] " + error);
        });
    }

    const GrabTeamworkCompletedTasksCount = async () => {
        fetch('/api/teamwork/metrics/tasks_count', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ type: "complete" })
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data);
            if (data["data"]["value"]) {
                setCompletedTasksCount(data["data"]["value"]);
            }
        })
        .catch(error => {
            console.log("[TEAMWORK GRAB CompletedTaskCount API] " + error);
        });
    }

    const GrabTeamworkLateTasksCount = async () => {
        fetch('/api/teamwork/metrics/tasks_count', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ type: "late" })
        })
        .then(response => response.json())
        .then(data => {
            if (data["data"]["value"]) {
                setLateTasksCount(data["data"]["value"]);
            }
        })
        .catch(error => {
            console.log("[TEAMWORK GRAB LateTaskCount API] " + error);
        });
    }

    const GrabTeamworkActiveProjectsCount = async () => {
        fetch('/api/teamwork/metrics/active_projects_count', {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        })
        .then(response => response.json())
        .then(data => {
            if (data["data"]["value"]) {
                setActiveProjectsCount(data["data"]["value"]);
            }
        })
        .catch(error => {
            console.log("[TEAMWORK GRAB ActiveProjectsCount API] " + error);
        });
    }

    const tasks_events_summary = async (e) => { // not used
        e.disabled = true;
        fetch('/api/tasks_events_summary', {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data);
            setSummary(data);
            e.disabled = false;
        })
        .catch(error => {
            console.log("[GET USER] " + error);
            e.disabled = false;
        });
    }

    useEffect(() => {
        get_user();
        GrabTeamworkProjects();
        GrabTeamworkCompletedTasksCount();
        GrabTeamworkLateTasksCount();
        GrabTeamworkActiveProjectsCount();

        // let teamworkTasklistsInterval;
        // if (selectedProjectTasklists === 0) {
        //     GrabTeamworkTasklists();
        //     teamworkTasklistsInterval = setInterval(GrabTeamworkTasklists, 15000);
        // }
        // else
        // {
        //     GrabTeamworkTasklistsSpecific();
        //     teamworkTasklistsInterval = setInterval(GrabTeamworkTasklistsSpecific, 15000);
        // }
    
        const teamworkProjectsInterval = setInterval(GrabTeamworkProjects, 15000);
        const teamworkCompletedTasksCountInterval = setInterval(GrabTeamworkCompletedTasksCount, 15000);
        const teamworkLateTasksCountInterval = setInterval(GrabTeamworkLateTasksCount, 15000);
        const teamworkActiveProjectsCountInterval = setInterval(GrabTeamworkActiveProjectsCount, 15000);

        return () => {
            clearInterval(teamworkProjectsInterval);
            clearInterval(teamworkCompletedTasksCountInterval);
            clearInterval(teamworkLateTasksCountInterval);
            clearInterval(teamworkActiveProjectsCountInterval);
            // clearInterval(teamworkTasklistsInterval);
        };
    }, []);

    useEffect(() => {
        GrabTeamworkMilestones();
        const teamworkMilestonesInterval = setInterval(() => {
            GrabTeamworkMilestones();
        }, 15000);

        return () => {
            clearInterval(teamworkMilestonesInterval);
        };
    }, [selectedProjectMilestones]);


    useEffect(() => {
        GrabTeamworkTasks();
        const teamworkTasksInterval = setInterval(() => {
            GrabTeamworkTasks();
        }, 15000);

        return () => {
            clearInterval(teamworkTasksInterval);
        };

    }, [showAllTasks, showCompletedTasks, showDeletedTasks, selectedProjectTasks]);

    useEffect(() => {
        GrabGoogleEvents();

        const googleEventsInterval = setInterval(() => {
            GrabGoogleEvents();
        }
        , 15000);

        return () => {
            clearInterval(googleEventsInterval);
        }

    }, [eventsStartDateFilter, eventsEndDateFilter, eventsStartTimeFilter, eventsEndTimeFilter]);

    useEffect(() => {
        GrabTeamworkFlaws();
        
        const teamworkFlawsInterval = setInterval(() => {
            GrabTeamworkFlaws();
        }, 15000);

        return () => {
            clearInterval(teamworkFlawsInterval);
        }

    }, [flawType]);

    useEffect(() => {
        setClickedMarkFlaws([]);

    }, [teamworkFlawsData]);

    useEffect(() => {
        setClickedMarkTasks([]);

    }, [teamworkTasksData]);

    return (
        <>
        {getLoggedIn() === true && (
            <html>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Personal Assistant - Dashboard</title>
                </Helmet>
                <body>
                    <TaskModal selectedTask={selectedTask} teamworkTasksData={teamworkTasksData} setSelectedTask={setSelectedTask} />
                    <EventModal selectedEvent={selectedEvent} />
                    <MilestoneModal selectedMilestone={selectedMilestone} />
                    <CreateEventModal />
                    <CreateMilestoneModal />
                    <CreateTaskModal />
                    {/* <TasklistViewer selectedTasklist={selectedTasklist} /> */}
                    <Nav />
                    <div id="dashboard" className="dashboard_frame">
                        <div className="frame_data_holder">
                            <div className="frame_data">
                                <div className="frame_data_heading">
                                    <div className="frame_data_title_holder">
                                        <p className="frame_data_title">Aandachtspunten</p>
                                        {teamworkFlawsData && teamworkFlawsData.length > 0 && (
                                            <p className="frame_counter">{teamworkFlawsData.length > 0 ? `${flawsViewLimit}/${teamworkFlawsData.length}` : ""}</p>
                                        )}
                                    </div>
                                    <img src={filterImg} alt="filter" className={`frame_data_filter ${flawsFilterEnabled === true ? 'sizeUp' : 'sizeDown'}`} onClick={e => setFlawsFilterEnabled(!flawsFilterEnabled)}/>
                                    
                                    <div className={`filter_frame ${flawsFilterEnabled === true ? 'visible' : 'hidden'}`}>
                                        <select className="filter_select" value={flawType} onChange={e => setFlawType(e.target.value)}>
                                            <option value="all">Alle aandachtspunten</option>
                                            <option value="unplanned">Niet gepland</option>
                                            <option value="unassigned">Geen verwijzing</option>
                                            <option value="no_time">Geen tijd</option>
                                            <option value="old_uncompleted">Niet afgemaakt en verlopen</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="frame_data_body">
                                    {teamworkFlawsData && teamworkFlawsData.length > 0 ? (
                                            teamworkFlawsData.slice(0, flawsViewLimit).map((task, index) => (
                                            <div className="frame_data_item" key={index}>
                                                <img 
                                                    src={task["completed"] === true ? checkedmarkImg : clickedMarkFlaws.includes(index) ? checkedmarkImg : checkmarkImg} 
                                                    alt="checkmark" 
                                                    className={`frame_data_checkmark ${clickedMarkFlaws.includes(index) ? 'expand' : ''}`}
                                                    onClick={e => handleClickFlaws(index, task)}
                                                />
                                                <div className="frame_data_item_body">
                                                    <p className="frame_data_item_text">{task['content']}</p>
                                                    <div className="frame_data_item_body_bottom">
                                                        <div className="frame_data_item_body_bottom_time">
                                                            <img src={hourglassImg} alt="hourglass" className="frame_data_item_body_bottom_time_image"></img>
                                                            <p className="frame_data_item_body_bottom_time_text">{task['estimated-minutes']} min</p>
                                                        </div>
                                                        <div className="frame_data_item_text_holder">
                                                            <p className="frame_data_item_body_bottom_project_text">{task['project-name']}</p>
                                                            {task["tags"] && (
                                                                <div className="frame_tags_holder">
                                                                    {task["tags"].map((tag, index) => (
                                                                        <p key={index} className="tag_circle" style={{backgroundColor: tag['color']}}>{tag["name"]}</p>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={pencilImg} alt="pencil" className="frame_data_pencil" onClick={() => viewTask(task, index)}></img>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="nothing_found_holder_item">Geen aandachtspunten gevonden.</p>
                                    )}
                                    {flawsViewLimit < flawsTotalViewLimit && teamworkFlawsData && teamworkFlawsData.length > 0 && (
                                        <button className="frame_data_show_more" onClick={() => showMore("flaws")}>Laat meer zien.</button>
                                    )}
                                </div>
                            </div>
                            <div className="frame_data">
                                <div className="frame_data_heading">
                                    <div className="frame_data_title_holder">
                                        <p className="frame_data_title">Mijlpalen</p>
                                        {teamworkMilestonesData && teamworkMilestonesData.length > 0 && (
                                            <p className="frame_counter">{teamworkMilestonesData.length > 0 ? `${milestonesViewLimit}/${teamworkMilestonesData.length}` : ""}</p>
                                        )}
                                    </div>

                                    <div className="frame_data_heading_buttons">
                                        <img src={addImg} alt="add" className="frame_data_add" onClick={() => viewCreateMilestone()}></img>
                                        <img src={filterImg} alt="filter" className={`frame_data_filter ${milestoneFilterEnabled === true ? 'sizeUp' : 'sizeDown'}`} onClick={e => setMilestoneFilterEnabled(!milestoneFilterEnabled)}/>
                                    </div>
                                    
                                    <div className={`filter_frame ${milestoneFilterEnabled === true ? 'visible' : 'hidden'}`}>
                                        <select className="filter_select" onChange={e => setSelectedProjectMilestones(e.target.value)}>
                                            <option value="0">Alle projecten</option>
                                            {teamworkProjectsData && teamworkProjectsData.length > 0 && (
                                                teamworkProjectsData.map((project, index) => (
                                                <option key={index} value={project['id']}>{project['name']}</option>
                                                ))
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="frame_data_body">
                                    {teamworkMilestonesData && teamworkMilestonesData.length > 0 ? (
                                            teamworkMilestonesData.slice(0, milestonesViewLimit).map((milestone, index) => (
                                            <div className="frame_data_item" key={index}>
                                                <img src={milestoneImg} alt="milestone" className="frame_data_milestone"></img>
                                                <div className="frame_data_item_body">
                                                    <p className="frame_data_item_text">{milestone['title']}</p>
                                                    <p className="frame_data_item_body_bottom_text">{dateToText(milestone['deadline'])}</p>  
                                                    <div className="frame_data_item_body_bottom">
                                                        <div className="frame_data_item_text_holder">
                                                            <p className="frame_data_item_body_bottom_project_text">{milestone['project-name']}</p>

                                                            {milestone["tags"] && (
                                                                <div className="frame_tags_holder">
                                                                    {milestone["tags"].map((tag, index) => (
                                                                        <p key={index} className="tag_circle" style={{backgroundColor: tag['color']}}>{tag["name"]}</p>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>  
                                                    </div>
                                                </div>
                                                <img src={pencilImg} alt="pencil" className="frame_data_pencil" onClick={() => viewMilestone(milestone, index)}></img>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="nothing_found_holder_item">Geen mijlpalen gevonden.</p>
                                    )}
                                    {milestonesViewLimit < milestonesTotalViewLimit && teamworkMilestonesData && teamworkMilestonesData.length > 0 && (
                                        <button className="frame_data_show_more" onClick={() => showMore("milestones")}>Laat meer zien.</button>
                                    )}
                                </div>
                            </div>
                            <div className="frame_data">
                                <div className="frame_data_heading">
                                    <div className="frame_data_title_holder">
                                        <p className="frame_data_title">Taken</p>
                                        {teamworkTasksData && teamworkTasksData.length > 0 && (
                                            <p className="frame_counter">{teamworkTasksData.length > 0 ? `${tasksViewLimit}/${teamworkTasksData.length}` : ""}</p>
                                        )}
                                    </div>

                                    <div className="frame_data_heading_buttons">
                                        <img src={addImg} alt="add" className="frame_data_add" onClick={() => viewCreateTask()}></img>
                                        <img src={filterImg} alt="filter" className={`frame_data_filter ${tasksFilterEnabled === true ? 'sizeUp' : 'sizeDown'}`} onClick={e => setTasksFilterEnabled(!tasksFilterEnabled)}/>
                                    </div>
                                    
                                    <div className={`filter_frame ${tasksFilterEnabled === true ? 'visible' : 'hidden'}`}>
                                        <select className="filter_select" onChange={e => setSelectedProjectTasks(e.target.value)}>
                                            <option value="0">Alle projecten</option>
                                            {teamworkProjectsData && teamworkProjectsData.length > 0 && (
                                                teamworkProjectsData.map((project, index) => (
                                                <option key={index} value={project['id']}>{project['name']}</option>
                                                ))
                                            )}
                                        </select>
                                        <select className="filter_select" value={showAllTasks} onChange={e => setShowAllTasks(e.target.value)}>
                                            <option value={1}>Mijn taken</option>
                                            <option value={-1}>Toegewezen taken</option>
                                            <option value={0}>Niet toegewezen taken</option>
                                        </select>
                                        <select className="filter_select" value={showCompletedTasks} onChange={e => setShowCompletedTasks(e.target.value)}>
                                            <option value={false}>Niet afgeronde taken</option>
                                            <option value={true}>Afgeronde taken</option>
                                        </select>
                                        <select className="filter_select" value={showDeletedTasks} onChange={e => setShowDeletedTasks(e.target.value)}>
                                            <option value={false}>Toon geen verwijderde taken</option>
                                            <option value={true}>Toon verwijderde taken</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="frame_data_body">
                                    {teamworkTasksData && teamworkTasksData.length > 0 ? (
                                            teamworkTasksData.slice(0, tasksViewLimit).map((task, index) => (
                                            <div className="frame_data_item" key={index}>
                                                <img 
                                                    src={task["completed"] === true ? checkedmarkImg : clickedMarkTasks.includes(index) ? checkedmarkImg : checkmarkImg} 
                                                    alt="checkmark" 
                                                    className={`frame_data_checkmark ${clickedMarkTasks.includes(index) ? 'expand' : ''}`}
                                                    onClick={e => handleClickTasks(index, task)}
                                                />
                                                <div className="frame_data_item_body">
                                                    <p className="frame_data_item_text">{task['content']}</p>
                                                    <div className="frame_data_item_body_bottom">
                                                        <div className="frame_data_item_body_bottom_time">
                                                            <img src={hourglassImg} alt="hourglass" className="frame_data_item_body_bottom_time_image"></img>
                                                            <p className="frame_data_item_body_bottom_time_text">{task['estimated-minutes']} min</p>
                                                        </div>
                                                        <div className="frame_data_item_text_holder">
                                                            <p className="frame_data_item_body_bottom_project_text">{task['project-name']}</p>
                                                            {task["tags"] && (
                                                                <div className="frame_tags_holder">
                                                                    {task["tags"].map((tag, index) => (
                                                                        <p key={index} className="tag_circle" style={{backgroundColor: tag['color']}}>{tag["name"]}</p>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={pencilImg} alt="pencil" className="frame_data_pencil" onClick={() => viewTask(task, index)}></img>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="nothing_found_holder_item">Geen taken gevonden.</p>
                                    )}
                                    {tasksViewLimit < tasksTotalViewLimit && teamworkTasksData && teamworkTasksData.length > 0 && (
                                        <button className="frame_data_show_more" onClick={() => showMore("tasks")}>Laat meer zien.</button>
                                    )}
                                </div>
                            </div>
                            <div className="frame_data">
                                <div className="frame_data_heading">
                                    <div className="frame_data_title_holder">
                                        <p className="frame_data_title">Afspraken</p>
                                        {googleEventsData && googleEventsData.length > 0 && (
                                            <p className="frame_counter">{googleEventsData.length > 0 ? `${googleEventsViewLimit}/${googleEventsData.length}` : ""}</p>
                                        )}
                                    </div>

                                    <div className="frame_data_heading_buttons">
                                        <img src={addImg} alt="add" className="frame_data_add" onClick={() => viewCreateEvent()}></img>
                                        <img src={filterImg} alt="filter" className={`frame_data_filter ${eventsFilterEnabled === true ? 'sizeUp' : 'sizeDown'}`} onClick={e => setEventsFilterEnabled(!eventsFilterEnabled)}/>
                                    </div>
                                    
                                    <div className={`filter_frame ${eventsFilterEnabled === true ? 'visible' : 'hidden'}`}>
                                        <div className="flex_events_filter">
                                            <p>Start:</p>
                                            <input type="date" value={eventsStartDateFilter} onChange={e => setEventsStartDateFilter(e.target.value)}></input>
                                        </div>
                                        <div className="flex_events_filter">
                                            <p>Eind:</p>
                                            <input type="date" value={eventsEndDateFilter} onChange={e => setEventsEndDateFilter(e.target.value)}></input>
                                        </div>
                                        <div className="flex_events_filter">
                                            <input type="time" value={eventsStartTimeFilter} onChange={e => setEventsStartTimeFilter(e.target.value)}></input>
                                            <p>-</p>
                                            <input type="time" value={eventsEndTimeFilter} onChange={e => setEventsEndTimeFilter(e.target.value)}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame_data_body">
                                    {googleEventsData && googleEventsData.length > 0 ? (
                                            googleEventsData
                                            .sort((a, b) => {
                                                const dateA = a.start.dateTime ? new Date(a.start.dateTime) : new Date(a.start.date);
                                                const dateB = b.start.dateTime ? new Date(b.start.dateTime) : new Date(b.start.date);
                                                return dateA - dateB;
                                            })
                                            .slice(0, googleEventsViewLimit)
                                            .map((event, index) => (
                                            <div className="frame_data_item" key={index}>
                                                <div className="frame_data_date_holder">
                                                    <p className="heading4 white">{event.start.date ? googleEventsDateToText(event.start.date, "dom") : googleEventsDateTimeToText(event.start.dateTime, "dom")}</p>
                                                    <p className="body-sm white">{event.start.date ? googleEventsDateToText(event.start.date, "dow") : googleEventsDateTimeToText(event.start.dateTime, "dow")}</p>
                                                </div>
                                                <div className="frame_data_item_body">
                                                    <p className="frame_data_item_text">{event.summary ? event.summary : "Geen titel."}</p>
                                                    <div className="frame_data_item_body_bottom_events">
                                                        <p className="frame_data_item_body_bottom_project_text">{event.location ? event.location : "Geen locatie."}</p>
                                                        <p className="frame_data_item_body_bottom_project_text">{event.start.dateTime ? (formatTime(event.start.dateTime) + " - " + formatTime(event.end.dateTime) + " uur") : "Hele dag."}</p>
                                                    </div>
                                                </div>
                                                <img src={pencilImg} alt="pencil" className="frame_data_pencil" onClick={() => viewEvent(event, index)}></img>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="nothing_found_holder_item">Geen afspraken gevonden.</p>
                                    )}
                                    {googleEventsViewLimit < googleEventsTotalViewLimit && googleEventsData && googleEventsData.length > 0 && (
                                        <button className="frame_data_show_more" onClick={() => showMore("google_events")}>Laat meer zien.</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
        )}
        </>
    );
}

export default Dashboard;