import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";

import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import Nav from './nav';

import TaskModal from "./taskModal";
import EventModal from "./eventModal";
import TimeboxEventModal from "./timeboxEventModal";

import { get_user, getLoggedIn, formatDateTeamwork, formatGoogleDate, formatGoogleDateTime, formatLocation, formatTime } from './frontendUtils';

import  { dateToText, googleEventsDateToText, googleEventsDateTimeToText } from './frontendUtils';

import '../css/timebox.css';
import '../css/main.css';

import pencilImg from '../images/Pencil.png';
import checkmarkImg from '../images/Check mark.png';
import hourglassImg from '../images/Hourglass.png';
import checkedmarkImg from '../images/Checked mark.png';

function Timebox() {
    const [teamworkTasksData, setTeamworkTasksData] = useState([]);
    const [googleEventsData, setGoogleEventsData] = useState([]);
    const [events, setEvents] = useState([]);
    const [initialEvents, setInitialEvents] = useState([
        {
            "id": 0,
            "title": 'Lunch',
            "start": new Date().toISOString().replace(/T.*$/, '') + 'T12:30:00',
            "end": new Date().toISOString().replace(/T.*$/, '') + 'T13:00:00',
            'editable': true,
            'color': '#298296',
            'textColor': 'white',
        },
        {
            "id": 1,
            "title": 'Buffer',
            "start": new Date().toISOString().replace(/T.*$/, '') + 'T17:00:00',
            "end": new Date().toISOString().replace(/T.*$/, '') + 'T17:30:00',
            'editable': true,
            'color': '#298296',
            'textColor': 'white',
        }
    ]);
    const [selectedTask, setSelectedTask] = useState({});
    const [selectedEvent, setSelectedEvent] = useState({});

    const [selectedTimeboxEvent, setSelectedTimeboxEvent] = useState();

    const [combined, setCombined] = useState([]);

    const [braindumpViewLimit, setBraindumpViewLimit] = useState(20);
    const [braindumpTotalViewLimit, setBraindumpTotalViewLimit] = useState(20);

    const [startTime, setStartTime] = useState('9:00:00') //useState(new Date().toISOString().replace(/T.*$/, '') + 'T9:00:00');
    const [endTime, setEndTime] = useState('17:30:00') //useState(new Date().toISOString().replace(/T.*$/, '') + 'T17:30:00');

    const [hasThread, setHasThread] = useState("no");
    
    const [clickedMarkTasks, setClickedMarkTasks] = useState([]);

    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [sendDisabled, setSendDisabled] = useState(false);

    function showMore() {
        setBraindumpViewLimit(braindumpViewLimit + 20);
    }

    useEffect(() => {
        mergeInitialEvents();

    }, [googleEventsData, events]);

    useEffect(() => {
        if (hasThread === "yes" || hasThread === "no") {
            setSendDisabled(false);
        }
        else {
            setSendDisabled(true);
        }
    }, [hasThread]);

    useEffect(() => {
        // console.log(messages);
        if (messages.length > 0) {
            setChatToLocalStorage(messages);
        }
    }, [messages]);

    function setChatToLocalStorage(messages) {
        localStorage.setItem('chatHistory', JSON.stringify(messages));
    }

    function clearChatFromLocalStorage() {
        localStorage.setItem('chatHistory', []);
    }

    function getChatFromLocalStorage() {
        let chatHistory = localStorage.getItem('chatHistory');
        if (chatHistory) {
            setMessages([]);
            // console.log("=========================================");
            // console.log(chatHistory);
            // console.log("=========================================");
            setMessages(JSON.parse(chatHistory));
        }
    }

    function setTimeboxToLocalStorage(events) {
        localStorage.setItem('timebox', JSON.stringify(events));
    }

    function getTimeboxFromLocalStorage() {
        let timebox = localStorage.getItem('timebox');
        // console.log("==============================");
        // console.log(timebox);
        // console.log("==============================");
        if (timebox) {
            timebox = JSON.parse(timebox);
            // console.log("=========================================")
            // console.log("Getting timebox from local storage")
            // console.log(timebox);
            // console.log("=========================================")
            setEvents(timebox);
        }
    }

    function clearTimeboxFromLocalStorage() {
        localStorage.setItem('timebox', []);
    }

    const handleMessageSend = (e) => {
        // console.log("sending message");
        if (inputValue.trim() === '') return;
        e.preventDefault();
        const newMessage = {
            text: "U: " + inputValue.trim(),
            user: "You",
        };
        TimeboxAI();
        setMessages(messages => [...messages, newMessage]);
        setInputValue('');
        // scrollToBottom();
    };

    const handleMessageManual = (text, user) => {

        const newMessage = {
            text: user + ": " + text,
            user: user,
        };
        setMessages(messages => [...messages, newMessage]);
    };

    const viewTask = (task, index) => {
        if (task) {
            setSelectedTask(task);
            if (document.querySelector(".taskModal")) {
                const modal = document.querySelector(".taskModal");
                modal.style.display = 'flex';
                modal.style.visibility = 'visible';
                modal.classList.add("appear");
    
                modal.addEventListener('animationend', (e) => {
                    if (e.animationName === 'appear') {
                        modal.classList.remove('appear');
                    }
                });
            }
        }
    }

    const viewEvent = (event, index) => {
        if (event) {
            setSelectedEvent(event);
            if (document.querySelector(".eventModal")) {
                const modal = document.querySelector(".eventModal");
                modal.style.display = 'flex';
                modal.style.visibility = 'visible';
                modal.classList.add("appear");
    
                modal.addEventListener('animationend', (e) => {
                    if (e.animationName === 'appear') {
                        modal.classList.remove('appear');
                    }
                });
            }
        }
    }
    
    const GrabTeamworkTasks = async () => {
        fetch('/api/teamwork/todays_tasks', { 
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[TEAMWORK GRAB API] Error getting Teamwork tasks");
            }
        })
        .then(data => {
            if (data['todo-items']) {
                // console.log(data['todo-items'])
                setTeamworkTasksData(data['todo-items']);
            }
        })
        .catch(error => {
            console.log("[TEAMWORK GRAB API] " + error);
        });
    }

    const GrabGoogleEvents = async () => {
        fetch('/api/google/todays_events', { 
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(response => response.json())
        .then(data => {
            setGoogleEventsData(data);
        })
        .catch(error => {
            console.log("[GOOGLE EVENTS GRAB API] " + error);
        });
    }

    useEffect(() => {
        if (googleEventsData.length > 0 && teamworkTasksData.length > 0) {
            const newCombined = [...teamworkTasksData, ...googleEventsData];
            setCombined(newCombined);
            setBraindumpTotalViewLimit(newCombined.length);
        }
        else if (teamworkTasksData.length > 0) {
            setCombined(teamworkTasksData);
            setBraindumpTotalViewLimit(teamworkTasksData.length);
        }
        else if (googleEventsData.length > 0 && teamworkTasksData.length === 0) {
            setCombined(googleEventsData);
            setBraindumpTotalViewLimit(googleEventsData.length);
        }
        else {
            setCombined([]);
            setBraindumpTotalViewLimit(0);
        }
    }, [teamworkTasksData, googleEventsData]);

    useEffect(() => {
        get_user();
        GrabTeamworkTasks();
        GrabGoogleEvents();
        getThread();

        const teamworkTasksInterval = setInterval(GrabTeamworkTasks, 15000);
        const googleEventsInterval = setInterval(GrabGoogleEvents, 15000);

        return () => {
            clearInterval(teamworkTasksInterval);
            clearInterval(googleEventsInterval);
        };
    }, []);

    function handleAddEvent(event) {
        let existingEvent = events.find(e => e.id === event.id.toString());

        if (!existingEvent) {
            let newEvent;

            let startDateTime = new Date();
            const [startHour, startMinute, startSecond] = startTime.split(':').map(Number);
            startDateTime.setHours(startHour);
            startDateTime.setMinutes(startMinute);
            startDateTime.setSeconds(startSecond);

            let endDateTime = new Date(startDateTime.getTime() + (event['estimated-minutes'] * 60000));

            newEvent = {
                "id": event['id'].toString(),
                "title": event['content'].toString(),
                "start": startDateTime.toISOString(),
                "end": endDateTime.toISOString()
            };

            setEvents(events => [...events, newEvent]);
        }
    }

    function filterTeamworkTasks(teamworkTasks) {
        const tasksByProject = {};
    
        teamworkTasks.forEach(task => {
            if (task['estimated-minutes'] !== 0) {
                const projectId = task['project-name'];
    
                if (!tasksByProject[projectId]) {
                    tasksByProject[projectId] = {
                        project: task['project'],
                        tasks: []
                    };
                }
    
                tasksByProject[projectId].tasks.push({
                    id: task['id'],
                    name: task['content'],
                    time: task['estimated-minutes']
                });
            }
        });
    
        return tasksByProject;
    }
    

    function filterInitialEvents(initialEvents) {
        const filteredEvents = initialEvents.map(event => {

            if (event['allDay'] === false || !event['allDay']) {

                const dateStart = new Date(event['start']);
                const dateEnd = new Date(event['end']);

                const dateStartTime = dateStart.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
                const dateEndTime = dateEnd.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });

                return {
                    // 'id': event['id'],
                    'event-name': event['title'],
                    'start': dateStartTime,
                    'end': dateEndTime
                };
            }
        })
        .filter(event => event !== undefined);

        return filteredEvents;
    }

    function filterGoogleEventsCalendar(googleEvents) {
        if (googleEvents.length > 0) {
            const filteredEvents = googleEvents.map(event => {

                return {
                    'id': event['id'],
                    'title': event['summary'],
                    'start': event['start']['dateTime'] ? event['start']['dateTime'].substring(0, 19) : event['start']['date'],
                    'end': event['end']['dateTime'] ? event['end']['dateTime'].substring(0, 19) : event['end']['date'],
                    'allDay': !event['end']['dateTime'],
                    'editable': false,
                    'color': '#298296',
                    'textColor': 'white',
                };
            });

            return filteredEvents
        }
        else
        {
            return [];
        }
    }

    function mergeInitialEvents() {
        const google_events = filterGoogleEventsCalendar(googleEventsData);

        const unique_google_events = google_events.filter(event => !initialEvents.find(initialEvent => initialEvent.id === event.id));

        const mergedEvents = [...initialEvents, ...unique_google_events];
                
        setInitialEvents(mergedEvents);
    }

    const TimeboxAI = async () => {
        setHasThread("inprogress");

        const filteredInitialEvents = filterInitialEvents(initialEvents);
        const filteredTeamworkTasks = filterTeamworkTasks(teamworkTasksData);

        const date = new Date();
        const hours = date.getHours();
        const minutes = date.getMinutes();

        fetch('/api/assistant/timebox', { 
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'teamworkTasks': filteredTeamworkTasks,
                'initialEvents': filteredInitialEvents,
                'extraInput': inputValue.trim(),
                'time_now': hours + ":" + (minutes < 10 ? "0" : "") + minutes
                // 'time_now': '11:00'
            })
        })
        .then(response => response.json())
        .then(data => {
            setHasThread("yes");
            if (data['response_message']) {
                handleMessageManual(data['response_message'], 'Assistent');
            }
            if (data['ai_timebox'] && data['ai_timebox'].length > 0) {
                setEvents(data['ai_timebox']);
                setTimeboxToLocalStorage(data['ai_timebox']);
            }
        })
        .catch(error => {
            setHasThread("error");
            handleMessageManual("Error: Er is iets misgegaan, reset de chat.", 'Assistent');
            console.log("[TIMEBOX API ERROR] " + error);
        });
    }

    const getThread = async () => {
        fetch('/api/assistant/thread', {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(response => {
            if (response.status === 200) {
                setHasThread("yes");
                getChatFromLocalStorage();
                getTimeboxFromLocalStorage();
            }
            else
            {
                setHasThread("no");
            }
        })
        .then(data => {
            // console.log(data);
        })
        .catch(error => {
            console.log("[GET THREAD API ERROR] " + error);
        });
    }

    const deleteThread = async () => {

        fetch('/api/assistant/thread', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'assistant_name': 'Virtual Personal Assistant' })
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[DELETE THREAD API] Error deleting thread");
            }
        })
        .then(data => {
            clearChatFromLocalStorage();
            clearTimeboxFromLocalStorage();
            setMessages([]);
            setHasThread("no");
            // document.getElementById("thread_status").style.backgroundColor = "red";
            handleMessageManual("Thread is gereset.", 'Assistent');
            handleMessageManual("Welkom! Hoe kan ik u helpen?", 'Assistent');
            // document.getElementById("chat_send_button").disabled = false;
            setEvents([]);
            // setResetButtonDisabled(true);
            // setInputValue('Timebox my tasks.');
        })
        .catch(error => {
            console.log("[DELETE THREAD API ERROR] " + error);
            handleMessageManual("Kon thread niet resetten.", 'Assistent');
            // document.getElementById("chat_send_button").disabled = false;
            // setInputValue('Timebox my tasks.');
        });
    }

    function scrollToBottom() {
        var chatMessages = document.querySelector('.chat_messages_holder');
        if (chatMessages) {
            chatMessages.scrollTop = chatMessages.scrollHeight;
        }
    }

    const CompleteTeamworkTask = async (id) => {
        fetch('/api/teamwork/task/complete', { 
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ task_id: id })
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[TEAMWORK COMPLETE API] Error getting Teamwork tasks");
            }
        })
        .then(data => {
        })
        .catch(error => {
            console.log("[TEAMWORK COMPLETE API] " + error);
        });
    }

    const UncompleteTeamworkTask = async (id) => {
        fetch('/api/teamwork/task/uncomplete', { 
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ task_id: id })
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[TEAMWORK COMPLETE API] Error getting Teamwork tasks");
            }
        })
        .then(data => {
        })
        .catch(error => {
            console.log("[TEAMWORK COMPLETE API] " + error);
        });
    }

    const handleClickTasks = (index, task) => {
        if (task.completed === true) {
            setClickedMarkTasks(clickedMarkTasks.filter(item => item !== index));
            UncompleteTeamworkTask(task.id);
            task.completed = false;
        } else {
            setClickedMarkTasks([...clickedMarkTasks, index]);
            CompleteTeamworkTask(task.id);
            task.completed = true;
        }
    };

    useEffect(() => {
        setClickedMarkTasks([]);

    }, [teamworkTasksData]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleConfirmation = (response, event) => {
        document.body.style.overflow = "auto";
        if (response === "delete") 
        {
            const eventIdToRemove = event._def.publicId.toString();

            const updatedInitialEvents = initialEvents.filter(event => event['id'].toString() !== eventIdToRemove);
            setInitialEvents(updatedInitialEvents);

            const updatedEvents = events.filter(event => event['id'].toString() !== eventIdToRemove);
            setEvents(updatedEvents);
            setTimeboxToLocalStorage(updatedEvents);

            event.remove();
        }
        else if (response === "add")
        {
            // console.log(event._instance.range.start.toISOString());
            // console.log(event._instance.range.end.toISOString());
            // console.log(event._def.allDay);
            fetch('/api/teamwork/create_event', { 
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ event_start: event._instance.range.start.toISOString(), event_end: event._instance.range.end.toISOString(), event_all_day: event._def.allDay, event_title: event._def.title })
            })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else
                {
                    console.log("[TEAMWORK CALENDAR API] Error creating Teamwork event");
                }
            })
            .then(data => {
            })
            .catch(error => {
                console.log("[TEAMWORK COMPLETE API] " + error);
            });
        }
        else if (response === "redirect")
        {
            window.open('https://portal.pixeldeluxe.nl/app/calendar', '_blank');
        }
    };

    function openTimeboxEventModal(clickInfo) {
        setSelectedTimeboxEvent(clickInfo.event);

        const modal = document.getElementById("timeboxEventModal");
        modal.style.display = 'flex';
        modal.style.visibility = 'visible';
        modal.classList.add("appear");

        if (document.getElementsByClassName("timebox")[0]) {
            modal.scrollIntoView({ behavior: "instant", block: "center" });
            document.body.style.overflow = "hidden";
        }

        modal.addEventListener('animationend', (e) => {
            if (e.animationName === 'appear' && e.target.id === "timeboxEventModal") {
                modal.classList.remove('appear');
            }
        });
    }

    return (
        <>
            {getLoggedIn() === true && (
            <html>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Personal Assistant - Timebox</title>
                </Helmet>
                <TaskModal selectedTask={selectedTask} teamworkTasksData={teamworkTasksData} setSelectedTask={setSelectedTask} />
                <EventModal selectedEvent={selectedEvent} />
                <TimeboxEventModal handleConfirmation={handleConfirmation} selectedTimeboxEvent={selectedTimeboxEvent} />
                <body>
                    <Nav />
                    <div id="timebox" className="timebox">
                        <div className="timebox_frame">
                            <div className="chat_holder">
                                <div className="chat_heading">
                                    <p className="frame_data_title">
                                        Top prioriteiten
                                    </p>
                                    {/* <img src={filterImg} alt="filter" className="frame_data_filter"></img> */}
                                </div>
                                <div className="frame_data_body_chat">
                                    <div className="chat_messages_holder">
                                        {messages.map((message) => (
                                            <div className={`message_${message.user}`}>
                                                {message.text}
                                            </div>
                                        ))}
                                        {messages.length === 0 && (
                                            <div className="message_Assistent">
                                                Assistent: Welkom! Hoe kan ik u helpen?
                                            </div>
                                        )}
                                    </div>
                                    <form className="chat_inputs_holder">
                                        <textarea type="text" className="chat_input" placeholder={hasThread === "no" ? "Geef hier uw 3 belangrijkste zaken die vandaag geregeld moeten zijn." : "Geef hier uw input om aanpassingen te maken aan timebox."} value={inputValue} onChange={(e) => setInputValue(e.target.value)} required={true}></textarea>
                                        <div className="chat_buttons">
                                            <button className="frame_button" type="submit" onClick={e => handleMessageSend(e)} disabled={sendDisabled}>Versturen</button>
                                            <button className="frame_button2" type="button" onClick={deleteThread}>Reset</button>
                                            <div className={hasThread === "no" ? "chat_status chat_off" : 
                                                hasThread === "inprogress" ? "chat_status chat_inprogress" :
                                                hasThread === "yes" ? "chat_status chat_on" :
                                                hasThread === "error" ? "chat_status chat_error" :
                                                "chat_status"}>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="brainddump_holder">
                                    <div className="frame_data_heading">
                                        <p className="frame_data_title">
                                            Brain dump
                                        </p>
                                    </div>
                                    <div className="braindump_frame_data_body">
                                        {combined && combined.length > 0 ? (
                                            combined
                                            .sort((a, b) => {
                                                if (a.start && b.start) {
                                                    return new Date(a.start.dateTime || a.start.date) - new Date(b.start.dateTime || b.start.date);
                                                } else if (a.start) {
                                                    return -1;
                                                } else if (b.start) {
                                                    return 1;
                                                } else {
                                                    return b["estimated-minutes"] - a["estimated-minutes"];
                                                }
                                            })
                                            .slice(0, braindumpViewLimit)
                                            .map((item, index) => (
                                                <>
                                                    {item && item.start && (
                                                        <div className="frame_data_item" key={index}>
                                                            <div className="frame_data_item">
                                                                <div className="frame_data_date_holder">
                                                                    <p className="heading4 white">{item.start.date ? googleEventsDateToText(item.start.date, "dom") : googleEventsDateTimeToText(item.start.dateTime, "dom")}</p>
                                                                    <p className="body-sm white">{item.start.date ? googleEventsDateToText(item.start.date, "dow") : googleEventsDateTimeToText(item.start.dateTime, "dow")}</p>
                                                                </div>
                                                                <div className="frame_data_item_body">
                                                                    <p className="frame_data_item_text">{item.summary ? item.summary : "Geen titel."}</p>
                                                                    <div className="frame_data_item_body_bottom_events">
                                                                        <p className="frame_data_item_body_bottom_project_text">{item.location ? item.location : "Geen locatie."}</p>
                                                                        <p className="frame_data_item_body_bottom_project_text">{item.start.dateTime ? (formatTime(item.start.dateTime) + " - " + formatTime(item.end.dateTime) + " uur") : "Hele dag."}</p>
                                                                    </div>
                                                                </div>
                                                                <img src={pencilImg} alt="pencil" className="frame_data_pencil" onClick={() => viewEvent(item, index)}></img>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {item && item['project-name'] && (
                                                        <div className="frame_data_item" key={index}>
                                                            {/* <img src={checkmarkImg} 
                                                            alt="checkmark"
                                                            className={`frame_data_checkmark ${clickedMarkTasks.includes(index) ? 'expand' : ''}`} 
                                                            onClick={e => handleClickTasks(index, item)}>
                                
                                                            </img> */}
                                                            <img 
                                                                src={item["completed"] === true ? checkedmarkImg : clickedMarkTasks.includes(index) ? checkedmarkImg : checkmarkImg} 
                                                                alt="checkmark" 
                                                                className={`frame_data_checkmark ${clickedMarkTasks.includes(index) ? 'expand' : ''}`}
                                                                onClick={e => handleClickTasks(index, item)}
                                                            />
                                                            <div className="frame_data_item_body">
                                                                <p className="frame_data_item_text">{item['content']}</p>
                                                                <div className="frame_data_item_body_bottom">
                                                                    <div className="frame_data_item_body_bottom_time">
                                                                        <img src={hourglassImg} alt="hourglass" className="frame_data_item_body_bottom_time_image"></img>
                                                                        <p className={item['estimated-minutes'] === 0 ? "frame_data_item_body_bottom_time_text error" : "frame_data_item_body_bottom_time_text"}>{item['estimated-minutes']} min</p>
                                                                    </div>
                                                                    <div className="frame_data_item_text_holder">
                                                                        <p className="frame_data_item_body_bottom_project_text">{item['project-name']}</p>
                                                                        {item["tags"] && (
                                                                            <div className="frame_tags_holder">
                                                                                {item["tags"].map((tag, index) => (
                                                                                    <p key={index} className="tag_circle" style={{backgroundColor: tag['color']}}>{tag["name"]}</p>
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <img src={pencilImg} alt="pencil" className="frame_data_pencil" onClick={() => viewTask(item, index)}></img>
                                                        </div>
                                                    )}
                                                </>
                                            ))
                                            ) : (
                                                <p className="nothing_found_holder_item">Niks gevonden.</p>
                                            )}
                                            {braindumpViewLimit < braindumpTotalViewLimit && combined && combined.length > 0 && (
                                                <button className="frame_data_show_more" onClick={(e) => showMore()}>Laat meer zien.</button>
                                            )}
                                    </div>
                                </div>
                            <div className="timebox_holder">
                                <div className="timebox_heading">
                                    <p className="frame_data_title">
                                        Afspraken
                                    </p>
                                    {/* <img src={filterImg} alt="filter" className="frame_data_filter"></img> */}
                                </div>
                                <div className="frame_data_body_timebox">
                                    <FullCalendar
                                        key={JSON.stringify(events) + JSON.stringify(initialEvents)}
                                        plugins={[timeGridPlugin, interactionPlugin]}
                                        headerToolbar={{
                                            left: '',
                                            center: '',
                                            right: ''
                                        }}
                                        dayHeaders={false}
                                        height="auto"
                                        slotDuration="00:15:00"
                                        // slotMinTime={new Date().toLocaleString('en-US', { hour: 'numeric', hour12: false }) + ':00'}
                                        slotMinTime={startTime}
                                        slotMaxTime={endTime}
                                        initialView='timeGridDay'
                                        editable={true}
                                        // selectable={true}
                                        selectMirror={true}
                                        dayMaxEvents={true}
                                        weekends={false}
                                        Draggable={true}
                                        initialEvents={initialEvents}
                                        events={events}
                                        eventColor="#D7C531"
                                        eventBackgroundColor="#D7C531"
                                        eventTextColor="black"
                                        nowIndicator={true}
                                        // eventContent={renderEventContent}
                                        eventClick={openTimeboxEventModal}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
            )}
        </>        
    );
}

export default Timebox;