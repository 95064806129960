import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";

import '../css/main.css';
import '../css/login.css';

import Nav from './nav';

import { setLoggedIn, statusHandler } from './frontendUtils';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

    const login = async (e) => {
        if (email !== "" && password !== "") {
            e.preventDefault();
        }
        else {
            return;
        }

        const status_handler = document.getElementById("login_status_handler");

        fetch('/api/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ email: email, password: password })
        })
        .then(response => {
            if (response.status === 401) {
                statusHandler(status_handler, "Verkeerde e-mailadres / wachtwoord!", "red");
                setLoggedIn(false);
                return;
            }
            else if (response.status === 200) {
                statusHandler(status_handler, "Inloggen successvol!", "green", false);
                setLoggedIn(true);
                window.location.href = "/dashboard";
                return response.json();
            }
            else {
                throw new Error(response.status + " " + response.statusText);
            }
        })
        .then(data => {
            console.log(data);
        })
        .catch(error => {
            console.log("[LOGIN] " + error);
            statusHandler(status_handler, "Kon niet inloggen! Probeer het opnieuw.", "red");
            setLoggedIn(false);
        });
    }

    const get_user = async () => {
        fetch('/api/get_user', {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
        })
        .then(data => {
            if (data['user_id']) {
                window.location.href = "/dashboard";
            }
        })
        .catch(error => {
            console.log("[GET USER] " + error);
        });
    }

    useEffect(() => {
        get_user();
    }, []);

    return (
        <html>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Personal Assistant - Inloggen</title>
            </Helmet>
            <body>
                <Nav/>
                <div className="frame_holder">
                    <div className="frame">
                        <p className="heading4">Inloggen</p>
                        <form className="frame_body">
                            <p id="login_status_handler" className="login_status_handler"></p>
                            <div className="input_field">
                                <p className="input_caption">E-mailadres</p>
                                <input type="email" placeholder="Uw e-mailadres" value={email} onChange={e => setEmail(e.target.value)} required={true}></input>
                            </div>
                            
                            <div className="input_field">
                                <p className="input_caption">Wachtwoord</p>
                                <div className="input_with_button">
                                    <input type={showPassword ? "text" : "password"} placeholder="Uw wachtwoord" className="login_input" value={password} onChange={e => setPassword(e.target.value)} required={true}></input>
                                    <button type="button" className="input_show_hide_button" onClick={togglePasswordVisibility}>
                                        {showPassword ? "Verbergen" : "Weergeven"}
                                    </button>
                                </div>
                            </div>

                            <div className="frame_bottom">
                                <button className="frame_button" type="submit" onClick={e => login(e)}>Inloggen</button>
                                <div className="frame_sidebuttons">
                                    <button className="frame_sidebutton" type="button" onClick={e => window.location.href = "/forgotpassword"}>Wachtwoord vergeten</button>
                                    <button className="frame_sidebutton" type="button" onClick={e => window.location.href = "/register"}>Ik heb nog geen account</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </body>
        </html>
    );
}

export default Login;