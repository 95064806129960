import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import {Helmet} from "react-helmet";

import '../css/changePassword.css';
import '../css/main.css'

import { statusHandler } from './frontendUtils';

import Nav from './nav';

function ChangePassword() {
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);


    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('setup');

        if (code === "" || code === null) {
            window.location.href = "/login";
        }
        else
        {
            checkCode(code);
        }
    }, []);

    const checkCode = async (code) => {
        fetch('/api/check_changepassword_key', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ key: code })
        })
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
            else
            {
                window.location.href = "/login";
            }
        })
        .catch(error => {
            console.log("[GET USER] " + error);
        });
    }

    const setPassword = async (e) => {
        const status_handler = document.getElementById("changepassword_status_handler");
        if (password1 === password2 && password1 !== "" && password2 !== "") {
            e.preventDefault();
            fetch('/api/change_password', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ password: password1 })
            })
            .then(response => {
                if (response.status === 201) {
                    statusHandler(status_handler, "Wachtwoord is succesvol ingesteld!", "green", false);
                    window.location.href = "/login";
                    return response.json();
                }
                else if (response.status === 400) {
                    statusHandler(status_handler, "Wachtwoord niet sterk genoeg!", "red");
                }
                else if (response.status === 409) {
                    statusHandler(status_handler, "Gebruiker heeft al een wachtwoord ingesteld!", "red");
                }
                else {
                    statusHandler(status_handler, "Kon wachtwoord niet instellen! Probeer het opnieuw.", "red");
                }
            })
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.log(error);
                statusHandler(status_handler, "Kon wachtwoord niet instellen! Probeer het opnieuw.", "red");
            });
        }
        else if (password1 !== password2)
        {
            e.preventDefault();
            statusHandler(status_handler, "Wachtwoorden komen niet overeen!", "red");
        }
    }

    return (
        <html>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Personal Assistant - Wachtwoord Instellen</title>
            </Helmet>
            <body>
                <Nav/>
                <div className="frame_holder">
                    <div className="frame">
                        <p className="heading4">Wachtwoord instellen</p>
                        <form className="frame_body">
                            <p id="changepassword_status_handler" className="changepassword_status_handler"></p>
                            <div className="input_field">
                                <p className="input_caption">Nieuw wachtwoord</p>
                                <div className="input_with_button">
                                    <input type={showPassword1 ? "text" : "password"} placeholder="Uw wachtwoord" className="login_input" value={password1} onChange={e => setPassword1(e.target.value)} required={true}></input>
                                    <button type="button" className="input_show_hide_button" onClick={e => setShowPassword1(!showPassword1)}>
                                        {showPassword1 ? "Verbergen" : "Weergeven"}
                                    </button>
                                </div>
                            </div>
                            <div className="input_field">
                                <p className="input_caption">Herhaal wachtwoord</p>
                                <div className="input_with_button">
                                    <input type={showPassword2 ? "text" : "password"} placeholder="Uw wachtwoord" className="login_input" value={password2} onChange={e => setPassword2(e.target.value)} required={true}></input>
                                    <button type="button" className="input_show_hide_button" onClick={e => setShowPassword2(!showPassword2)}>
                                        {showPassword2 ? "Verbergen" : "Weergeven"}
                                    </button>
                                </div>
                            </div>
                            
                            <div className="frame_bottom">
                                <button className="frame_button" type="submit" onClick={e => setPassword(e)}>Instellen</button>
                                <div className="frame_sidebuttons">
                                    <button type="button" className="frame_sidebutton" onClick={e => window.location.href = "/login"}>Ik weet mijn wachtwoord wel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </body>
        </html>
    );
}

export default ChangePassword;
