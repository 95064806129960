import React, { useState, useEffect } from "react";

import '../css/taskModal.css';
import '../css/main.css';

import Cancel from '../images/Cancel.png';

import VerifyModal from "./verifyModal";

import { dateTimeToFullText, statusHandler } from './frontendUtils';

function TaskModal({ selectedTask, teamworkTasksData, setSelectedTask }) {
    const [content, setContent] = useState("");
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState("none");
    const [dueDate, setDueDate] = useState("");
    const [startDate, setStartDate] = useState("");
    const [isCompleted, setIsCompleted] = useState(false);
    const [estimatedMinutes, setEstimatedMinutes] = useState(0);

    const [peopleData, setPeopleData] = useState([]);
    const [selectedPeople, setSelectedPeople] = useState([]);
    const [projectTaskLists, setProjectTaskLists] = useState([]);
    const [selectedTasklist, setSelectedTasklist] = useState("");
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const [verifyModalType, setVerifyModalType] = useState("save");

    const handleConfirmation = (response) => {
        document.getElementsByClassName("taskModal")[0].style.overflow = "auto";
        if (response === true) {
            if (verifyModalType === "delete") {
                deleteTask();
            }
            else if (verifyModalType === "save") {
                saveSelectedTask();
            }
        }
    };

    const saveSelectedTask = async () => {
        const e = document.getElementById("task_status_handler");

        fetch('/api/teamwork/update_task', {
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                task_id: selectedTask['id'],
                task_content: content,
                task_description: description,
                task_priority: priority,
                task_due_date: dueDate,
                task_start_date: startDate,
                task_is_completed: isCompleted,
                task_estimated_minutes: estimatedMinutes,
                task_responsible_party_ids: selectedPeople.join(','),
                task_tasklist_id: selectedTasklist,
                task_tags: selectedTags.join(','),
            })
        })
        .then(response => {
            if (response.status === 200) {
                statusHandler(e, "Taak is opgeslagen!", "green", false);
            }
            else
            {
                statusHandler(e, "Taak kon niet worden opgeslagen!", "red");
            }
        })
        .then(data => {
            // console.log(data);
        })
        .catch(error => {
            console.log("[SAVE TEAMWORK TASK] " + error);
            statusHandler(e, "Taak kon niet worden opgeslagen!", "red");
        });
    }

    const getEstimateTaskDuration = async () => {
        const e = document.getElementById("task_status_handler");

        fetch('/api/assistant/estimate_task_time_priority', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                'task_name': content,
                'task_description': description,
                'task_due_date': dueDate,
                'task_start_date': startDate,
            }),
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                statusHandler(e, "AI kon de taak niet berekenen!", "red");
            }
        })
        .then(data => {
            if (data) {
                setPriority(data['priority']);
                setEstimatedMinutes(data['time']);
                statusHandler(e, "AI heeft de taak berekend!", "green", false);
            }
            else
            {
                statusHandler(e, "AI kon de taak niet berekenen!", "red");
            }
        })
        .catch(error => {
            console.log("[OPENAI] " + error);
        });
    }

    const formatDateTeamwork = (dateString, type="default") => {
        if (!dateString || dateString === null || dateString === "") {
            return "No date";
        }
        let year = dateString.substring(0, 4);
        let month = dateString.substring(4, 6);
        let day = dateString.substring(6, 8);
        if (type === "default") {
            return `${day}/${month}/${year}`;
        }
        else {
            return `${year}-${month}-${day}`;
        }
    }

    const GrabPeopleInfo = async (selectedTask) => {
        if (selectedTask['project-id']) {
            fetch('/api/teamwork/project', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    'project-id': selectedTask['project-id']
                })
            })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else
                {
                    console.log("[PEOPLE INFO] Error getting people info");
                }
            })
            .then(data => {
                // console.log(data);
                setPeopleData(data['people']);
                // console.log(data['people']);
            })
            .catch(error => {
                console.log("[PEOPLE INFO] " + error);
            });
        }
    }

    const GrabProjectTasklists = async (project_id) => {
        if (project_id) {
            fetch('/api/teamwork/project_task_list', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ project_id: project_id })
            })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else
                {
                    console.log("[PROJECT TASKLISTS] Error getting project tasklists");
                }
            })
            .then(data => {
                setProjectTaskLists(data['tasklists']);
            })
            .catch(error => {
                console.log("[PROJECT TASKLISTS] " + error);
            });
        }
    }

    const GrabAllTags = async () => {
        fetch('/api/teamwork/tags', {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[ALL TAGS] Error getting all tags");
            }
        })
        .then(data => {
            setTags(data['tags']);
        })
        .catch(error => {
            console.log("[ALL TAGS] " + error);
        });
    }

    const deleteTask = async () => {
        const e = document.getElementById("task_status_handler");

        fetch('/api/teamwork/delete_task', {
            method: 'DELETE',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ task_id: selectedTask['id'] })
        })
        .then(response => {
            if (response.status === 200) {
                statusHandler(e, "Taak is verwijderd!", "green", false);
            }
            else
            {
                statusHandler(e, "Taak kon niet worden verwijderd!", "red");
            }
        })
        .then(data => {
            // console.log(data);
        })
        .catch(error => {
            console.log("[DELETE TEAMWORK TASK] " + error);
            statusHandler(e, "Taak kon niet worden verwijderd!", "red");
        });
    }

    function redirectToTeamwork() {
        window.open(`https://portal.pixeldeluxe.nl/app/tasks/${selectedTask['id']}`);
    }

    useEffect(() => {
        setProjectTaskLists([]);
        setPeopleData([]);
        if (!selectedTask) return;
        
        setSelectedTasklist(selectedTask['todo-list-id'] ? selectedTask['todo-list-id'] : "");
        setContent(selectedTask["content"] ? selectedTask["content"] : "");
        setDescription(selectedTask["description"] ? selectedTask["description"] : "");
        setPriority(selectedTask["priority"] ? selectedTask["priority"] : "");
        setDueDate(formatDateTeamwork(selectedTask['due-date'] ? selectedTask['due-date'] : "", "input"));
        setStartDate(formatDateTeamwork(selectedTask['start-date'] ? selectedTask['start-date'] : "", "input"));
        setIsCompleted(selectedTask['completed']);
        setEstimatedMinutes(selectedTask['estimated-minutes'] ? selectedTask['estimated-minutes'] : 0);
        GrabPeopleInfo(selectedTask);
        GrabProjectTasklists(selectedTask['project-id']);
        GrabAllTags();

        const responsiblePartyIds = selectedTask['responsible-party-id'] ? selectedTask['responsible-party-id'].split(',') : [];
        setSelectedPeople(responsiblePartyIds);

        if (selectedTask['tags']) {
            const taskTags = selectedTask['tags'];
            const tagIds = taskTags.map(tag => tag.id.toString());

            setSelectedTags(tagIds);    
        }
    }, [selectedTask]);

    const handleCheckboxChange1 = (e, person) => {
        if (e.target.checked === true) {
            if (!selectedPeople.includes(person)) {
                setSelectedPeople([...selectedPeople, person]);
            }
        } else {
            setSelectedPeople(selectedPeople.filter(p => p !== person));
        }
    }

    const handleCheckboxChange2 = (e, tag) => {
        if (e.target.checked === true) {
            if (!selectedTags.includes(tag)) {
                setSelectedTags([...selectedTags, tag]);
            }
        } else {
            setSelectedTags(selectedTags.filter(p => p !== tag));
        }
    }

    function closeModal(e) {
        if (!e || e.id === "modal") {
            const modal = document.querySelector(".taskModal");
            modal.classList.add("disappear");

            document.body.style.overflow = "auto";

            if (document.getElementById("verifyTaskModal")) {
                const verifyModal = document.getElementById("verifyTaskModal");
                verifyModal.style.display = "none";
                verifyModal.style.visibility = "hidden";
            }

            modal.addEventListener('animationend', (e) => {
                if (e.animationName === 'disappear' && e.target.id === "modal") {
                    modal.style.display = "none";
                    modal.style.visibility = "hidden";
                    modal.classList.remove('disappear');
                }
            });
        }
    }

    function openVerifyModal(type) {
        setVerifyModalType(type);
        const modal = document.getElementById("verifyTaskModal");
        modal.style.display = 'flex';
        modal.style.visibility = 'visible';
        modal.classList.add("appear");

        if (document.getElementsByClassName("taskModal")[0]) {
            modal.scrollIntoView({ behavior: "instant", block: "center" });
            document.getElementsByClassName("taskModal")[0].style.overflow = "hidden";
        }

        modal.addEventListener('animationend', (e) => {
            if (e.animationName === 'appear' && e.target.id === modal.id) {
                modal.classList.remove('appear');
            }
        });
    }

    return (
        <>
            {selectedTask && (
                <div id="modal" className="taskModal" onClick={e => closeModal(e.target)}>
                    <VerifyModal handleConfirmation={handleConfirmation} type={verifyModalType} id="verifyTaskModal"/>
                    <div className="taskModal_holder">
                        <div className="modal_header">
                            <p className="heading4">Taak gegevens</p>
                            <img src={Cancel} alt="cancelImg" className="close_modal_button" onClick={e => closeModal()}></img>
                        </div>
                        <div className="modal_body">
                            <div className="modal_side">
                                <div className="modal_textarea_holder">
                                    <p>Beschrijving</p>
                                    <textarea className="modal_textarea" value={description} placeholder="Beschrijving van taak" onChange={e => setDescription(e.target.value)}></textarea>
                                </div>
                                <div className="modal_item">
                                    <p>Aangemaakt</p>
                                    <input type="text" className="modal_input" placeholder="Aangemaakt [door en wanneer]" value={selectedTask['creator-firstname'] + " " + selectedTask['creator-lastname'] + " [" + selectedTask['creator-id'] + "] " + dateTimeToFullText(selectedTask['created-on'])} disabled={true}></input>
                                </div>
                                <div className="modal_item">
                                    <p>Laatst aangepast</p>
                                    <input type="text" className="modal_input" placeholder="Laatst aangepast [door en wanneer]" value={selectedTask['updater-firstname'] + " " + selectedTask['updater-lastname'] + " [" + selectedTask['updater-id'] + "] " + dateTimeToFullText(selectedTask['last-changed-on'])} disabled={true}></input>
                                </div>
                                <div className="milestoneModal_lists_holder">
                                    <div className="modal_list_holder">
                                        <p>Lijst</p>
                                        <div className="list_body milestoneModal_list">
                                            {projectTaskLists.length > 0 ? (
                                                projectTaskLists.map((tasklist, index) =>
                                                    <div className="switch_holder" key={index}>
                                                        <label className="switch">
                                                            <input type="checkbox" onChange={e => setSelectedTasklist(tasklist.id)} checked={selectedTasklist.toString() === tasklist.id.toString()}></input>
                                                            <span className="slider round"></span>
                                                        </label>
                                                        <p className="switch_text">{tasklist['name']}</p>
                                                    </div>
                                                )
                                            )
                                            :
                                            (
                                                <p>Geen lijsten beschikbaar</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="modal_list_holder">
                                        <p>Verantwoordelijk</p>
                                        <div className="list_body milestoneModal_list">
                                            {peopleData.length > 0 ? (
                                                peopleData.map((person, index) =>
                                                    <div className="switch_holder" key={index}>
                                                        <label className="switch">
                                                            <input type="checkbox" onChange={e => handleCheckboxChange1(e, person["id"])} checked={selectedPeople.includes(person["id"])}></input>
                                                            <span className="slider round"></span>
                                                        </label>
                                                        <p className="switch_text">{person['firstName']} {person['lastName']}</p>
                                                    </div>
                                                )
                                            )
                                            :
                                            (
                                                <p>Niemand beschikbaar</p>
                                            )}
                                        </div>
                                    </div>
                                    <div class="modal_list_holder">
                                        <p>Tags</p>
                                        <div class="list_body milestoneModal_list">
                                            {tags.length > 0 ? (
                                                tags.map((tag, index) =>
                                                    <div class="switch_holder" key={index}>
                                                        <label class="switch">
                                                            <input type="checkbox" onChange={e => handleCheckboxChange2(e, tag['id'])} checked={selectedTags.includes(tag["id"])}></input>
                                                            <span class="slider round"></span>
                                                        </label>
                                                        <p class="switch_text tags" style={{backgroundColor: tag['color'], color: "white"}}>{tag['name']}</p>
                                                    </div>
                                                )
                                            )
                                            :
                                            (
                                                <p>Geen tags beschikbaar</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal_side space_between">
                                <div className="modal_rightside">
                                    <div className="modal_item">
                                        <p>Titel</p>
                                        <input type="text" className="modal_input" placeholder="Titel van mijlpaal" value={content} onChange={e => setContent(e.target.value)}></input>
                                    </div>
                                    <div className="modal_item">
                                        <p>Start datum</p>
                                        <input type="date" className="modal_datepicker" value={startDate} onChange={e => setStartDate(e.target.value)}></input>
                                    </div>
                                    <div className="modal_item">
                                        <p>Eind datum</p>
                                        <input type="date" className="modal_datepicker" value={dueDate} onChange={e => setDueDate(e.target.value)}></input>
                                    </div>
                                    <div className="modal_item">
                                        <p>Prioriteit</p>
                                        <select className="modal_select" value={priority} onChange={e => setPriority(e.target.value)}>
                                            <option value="none">Geen</option>
                                            <option value="low">Laag</option>
                                            <option value="medium">Medium</option>
                                            <option value="high">Hoog</option>
                                        </select>
                                    </div>
                                    <div className="modal_item">
                                        <p>Tijdsduur</p>
                                        <input className="modal_number" type="number" min="0" max="480" value={estimatedMinutes} onChange={e => setEstimatedMinutes(e.target.value)}></input>
                                    </div>
                                    <div className="switch_holder">
                                        <label className="switch">
                                            <input type="checkbox" onChange={e => setIsCompleted(e.target.checked)} checked={isCompleted}></input>
                                            <span className="slider round"></span>
                                        </label>
                                        <p className="switch_text">Afgerond</p>
                                    </div>
                                </div>
                                <div className="modal_buttons_holder">
                                    <button className="modal_button_warning" onClick={e => openVerifyModal("delete")}>Verwijderen</button>
                                    <button className="modal_button" onClick={e => getEstimateTaskDuration()}>A.I. berekening</button>
                                    <button className="modal_button" onClick={e => redirectToTeamwork()}>Ga naar taak</button>
                                    {selectedTask["parent-task"] && (
                                        <>
                                        {teamworkTasksData.map((task, index) => {
                                            const taskId = String(task['id']);
                                            const parentTaskId = String(selectedTask["parent-task"]["id"]);
                                            if (taskId === parentTaskId) {
                                                return (
                                                    <button className="modal_button" onClick={() => setSelectedTask(task)} key={index}>Bovenliggende taak</button>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal_bottom_buttons">
                            <div id="task_status_handler" className="modal_status_handler"></div>
                            <button className="frame_button" onClick={e => openVerifyModal("save")}>Opslaan</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default TaskModal;
