import React, { useState, useEffect } from "react";

import "../css/verifyModal.css";

import Cancel from '../images/Cancel.png';

function VerifyModal({ handleConfirmation, type, id }) {
    const handleButtonClick = (response) => {
        handleConfirmation(response);
        closeVerifyModal();
    };

    const closeVerifyModal = (e) => {
        if (!e || e.id === id) {
            if (document.getElementById(id)) {
                const modal = document.getElementById(id);
                modal.classList.add("disappear");

                modal.addEventListener('animationend', (e) => {
                    if (e.animationName === 'disappear' && e.target.id === id) {
                        modal.style.display = "none";
                        modal.style.visibility = "hidden";
                        modal.classList.remove('disappear');
                    }
                });
                handleConfirmation("");
            }
        }
    }

    return (
        <>
            <div id={id} className="verifyModal_holder" onClick={e => closeVerifyModal(e.target)}>
                <div className="verifyModal">
                    <p className="heading4">Weet u het zeker?</p>
                    {type === "delete" && <p className="body1">Weet u zeker dat u dit item wilt verwijderen?</p>}
                    {type === "save" && <p className="body1">Weet u zeker dat u dit item wilt opslaan?</p>}
                    {type === "complete" && <p className="body1">Weet u zeker dat u dit item wilt afronden?</p>}
                    {type === "uncomplete" && <p className="body1">Weet u zeker dat u dit item wilt heropenen?</p>}
                    {type === "create" && <p className="body1">Weet u zeker dat u dit item wilt aanmaken?</p>}
                    <img src={Cancel} alt="cancelImg" className="close_verify_button" onClick={() => closeVerifyModal()}></img>
                    <div className="verify_buttons_holder">
                        <button className="verify_button cancel" onClick={() => closeVerifyModal()}>Nee</button>
                        <button className="verify_button accept" onClick={() => handleButtonClick(true)}>Ja</button>
                    </div>
                </div>
            </div>
        </>        
    );
}

export default VerifyModal;