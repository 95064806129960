import React, { useState, useEffect } from "react";

import '../css/createEventModal.css';

import Cancel from '../images/Cancel.png';

import VerifyModal from "./verifyModal";

import { getUserTimezone, statusHandler } from './frontendUtils';

function CreateEventModal() {

    const [summary, setSummary] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    const [status, setStatus] = useState('confirmed');
    const [visibility, setVisibility] = useState('default');

    const [verifyModalType, setVerifyModalType] = useState("save");

    const handleConfirmation = (response) => {
        document.getElementById("CreateEventModal").style.overflow = "auto";
        if (response === true) {
            if (verifyModalType === "create") {
                createEvent();
            }
        }
    };

    const createEvent = async (button) => {
        const e = document.getElementById("create_event_status_handler");

        fetch('/api/google/create_event', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify({ 
                'summary': summary,
                'description': description,
                'location': location,
                'startTime': startTime,
                'endTime': endTime,
                'startDate': startDate,
                'endDate': endDate,
                'status': status,
                'visibility': visibility,
                'timezone': getUserTimezone()
            })
        })
        .then(response => {
            if (response.status === 201) {
                statusHandler(e, "Afspraak is aangemaakt!", "green", false);
            }
            else
            {
                statusHandler(e, "Afspraak kon niet aangemaakt worden!", "red");
            }
        })
        .catch(error => {
            console.log("[SAVE EVENT] " + error);
            statusHandler(e, "Afspraak kon niet aangemaakt worden!", "red");
        });
    }

    function closeModal(e) {
        if (!e || e.id === "CreateEventModal") {
            const modal = document.getElementById("CreateEventModal");
            modal.classList.add("disappear");

            document.body.style.overflow = "auto";

            modal.addEventListener('animationend', (e) => {
                if (e.animationName === 'disappear' && e.target.id === "CreateEventModal") {
                    modal.style.display = "none";
                    modal.style.visibility = "hidden";
                    modal.classList.remove('disappear');
                }
            });

            setSummary('');
            setDescription('');
            setLocation('');
            setStartDate('');
            setEndDate('');
            setStartTime('');
            setEndTime('');
            setStatus('confirmed');
            setVisibility('default');
        }
    }

    function openVerifyModal(type) {
        setVerifyModalType(type);
        const modal = document.getElementById("verifyCreateEventModal");
        modal.style.display = 'flex';
        modal.style.visibility = 'visible';
        modal.classList.add("appear");

        if (document.getElementById("CreateEventModal")) {
            modal.scrollIntoView({ behavior: "instant", block: "center" });
            document.getElementById("CreateEventModal").style.overflow = "hidden";
        }

        modal.addEventListener('animationend', (e) => {
            if (e.animationName === 'appear' && e.target.id === modal.id) {
                modal.classList.remove('appear');
            }
        });
    }

    return (
        <>
            <div id="CreateEventModal" className="CreateModal" onClick={e => closeModal(e.target)}>
                <VerifyModal handleConfirmation={handleConfirmation} type={verifyModalType} id="verifyCreateEventModal"/>
                <div className="CreateModal_holder">
                    <div className="modal_header">
                        <p className="heading4">Afspraak aanmaken</p>
                        <img src={Cancel} alt="cancelImg" className="close_modal_button" onClick={e => closeModal()}></img>
                    </div>
                    <div className="modal_body">
                        <div className="modal_side">
                            <div className="modal_textarea_holder">
                                <p>Beschrijving</p>
                                <textarea className="create_modal_textarea" value={description} placeholder="Beschrijving van afspraak" onChange={e => setDescription(e.target.value)}></textarea>
                            </div>
                        </div>
                        <div className="modal_side space_between">
                            <div className="modal_item">
                                <p>Titel</p>
                                <input type="text" className="modal_input" placeholder="Titel van afspraak" value={summary} onChange={e => setSummary(e.target.value)}></input>
                            </div>
                            <div className="modal_item">
                                <p>Locatie</p>
                                <input type="text" className="modal_input" placeholder="Locatie" value={location} onChange={e => setLocation(e.target.value)}></input>
                            </div>
                            <div className="modal_item_combo">
                                <div className="modal_item">
                                    <p>Start datum</p>
                                    <input type="date" className="modal_datepicker" value={startDate} onChange={e => setStartDate(e.target.value)} required={true}></input>
                                </div>
                                <div className="modal_item">
                                    <p>End datum</p>
                                    <input type="date" className="modal_datepicker" value={endDate} onChange={e => setEndDate(e.target.value)} required={true}></input>
                                </div>
                            </div>
                            <div className="modal_item_combo">
                                <div className="modal_item">
                                    <p>Start Time</p>
                                    <input type="time" className="modal_timepicker" value={startTime} onChange={e => setStartTime(e.target.value)} required={true}></input>
                                </div>
                                <div className="modal_item">
                                    <p>End Time</p>
                                    <input type="time" className="modal_timepicker" value={endTime} onChange={e => setEndTime(e.target.value)} required={true}></input>
                                </div>
                            </div>
                            <div className="modal_item">
                                <p>Status</p>
                                <select className="modal_select" value={status} onChange={e => setStatus(e.target.value)}>
                                    <option value="confirmed">Bevestigd</option>
                                    <option value="tentative">Voorlopig</option>
                                    <option value="cancelled">Geannuleerd</option>
                                </select>
                            </div>
                            <div className="modal_item">
                                <p>Privacy</p>
                                <select className="modal_select" value={visibility} onChange={e => setVisibility(e.target.value)}>
                                    <option value="default">Standaard</option>
                                    <option value="public">Openbaar</option>
                                    <option value="private">Privé</option>
                                    <option value="confidential">Vertrouwelijk</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="modal_bottom_buttons">
                        <div id="create_event_status_handler" className="modal_status_handler"></div>
                        <button className="frame_button" onClick={e => openVerifyModal("create")}>Aanmaken</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateEventModal;
