import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import '../css/main.css';
import '../css/nav.css';

import Chevron from '../images/Chevron.png';

function Nav() {
    const location = useLocation();
    const [loggedIn, setLoggedIn] = useState(false);
    const [dropDown, setDropDown] = useState(false);

    const logOut = async () => {
        fetch('/api/logout', {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(response => {
            if (response.status === 200) {
                window.location.href = "/logout";
            }
            else
            {
                console.log("Error logging out");
            }
        })
        .catch(error => {
            console.log("[LOGOUT] " + error);
        });
    }


    const isCurrentPage = (pageName) => {
        return location.pathname === pageName;
    };

    const get_user = async () => {
        fetch('/api/get_user', {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
        })
        .then(data => {
            if (data['user_id']) {
                setLoggedIn(true);
            }
            if (isCurrentPage("/") || isCurrentPage("/login") || isCurrentPage("/register") || isCurrentPage("/forgotpassword") || isCurrentPage("/changepassword")) {
                if (data['user_id']) {
                    window.location.href = "/dashboard";
                }
            }
        })
        .catch(error => {
            console.log("[GET USER] " + error);
        });
    }

    useEffect(() => {
        get_user();
    }, []);

    useEffect(() => {
        if (dropDown === true) {
            document.querySelector(".navbar_dropdown").style.marginTop = "70px";
        }
        else {
            document.querySelector(".navbar_dropdown").style.marginTop = "-10%";
        }
    }, [dropDown]);

    return (
        <>
        <nav>
            <button className="application_name" onClick={e => loggedIn === true ? window.location.href = "/dashboard" : window.location.href = "/login"}>Virtual assistant</button>
            <div className="navbar_sidebuttons">
                {(isCurrentPage("/") || isCurrentPage("/login") || isCurrentPage("/register") || isCurrentPage("/forgotpassword") || isCurrentPage("/changepassword")) && (
                    <>
                    <button className="navbar_sidebutton" disabled={isCurrentPage("/login") || isCurrentPage("/")} onClick={e => window.location.href = "/login"}>Inloggen</button>
                    <button className="navbar_sidebutton" disabled={isCurrentPage("/register") || isCurrentPage("/changepassword") || isCurrentPage("/forgotpassword")} onClick={e => window.location.href = "/register"}>Registreren</button>
                    </>
                )}
                {(isCurrentPage("/dashboard") || isCurrentPage("/timebox") || isCurrentPage("/settings")) && (
                    <>
                    <button className="navbar_sidebutton" disabled={isCurrentPage("/dashboard")} onClick={e => window.location.href = "/dashboard"}>Dashboard</button>
                    <button className="navbar_sidebutton" disabled={isCurrentPage("/timebox")} onClick={e => window.location.href = "/timebox"}>Timebox</button>
                    <div className="navbar_dropdown_holder">
                        <button className={dropDown === true ? "navbar_sidebutton selected" : "navbar_sidebutton"} onClick={e => setDropDown(!dropDown)}>Mijn account</button>
                        <img src={Chevron} alt="chevron" className={dropDown === true ? "chevron_image selected flipDown" : "chevron_image flipUp"}></img>
                    </div>
                    </>
                )}
            </div>
        </nav>
        <div className="navbar_dropdown">
            <button className="navbar_sidebutton" disabled={isCurrentPage("/settings")} onClick={e => window.location.href = "/settings"}>Instellingen</button>
            <button className="navbar_sidebutton" onClick={logOut}>Uitloggen</button>
        </div>
        </>        
    );
}

export default Nav;