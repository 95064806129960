import React, { useState, useEffect } from "react";

import '../css/milestoneModal.css';
import '../css/main.css';

import Cancel from '../images/Cancel.png';

import VerifyModal from "./verifyModal";

import { statusHandler } from './frontendUtils';

function MilestoneModal({ selectedMilestone }) {
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [privateMilestone, setPrivate] = useState(true);
    const [reminder, setReminder] = useState(true);
    const [deadline, setDeadline] = useState("");
    const [peopleData, setPeopleData] = useState([]);
    const [selectedPeople, setSelectedPeople] = useState([]);
    const [projectTaskLists, setProjectTaskLists] = useState([]);
    const [selectedTasklist, setSelectedTasklist] = useState("");

    const [verifyModalType, setVerifyModalType] = useState("save");
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const handleCheckboxChange3 = (e, tag) => {
        if (e.target.checked === true) {
            if (!selectedTags.includes(tag)) {
                setSelectedTags([...selectedTags, tag]);
            }
        } else {
            setSelectedTags(selectedTags.filter(p => p !== tag));
        }
    }

    const GrabAllTags = async () => {
        fetch('/api/teamwork/tags', {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[ALL TAGS] Error getting all tags");
            }
        })
        .then(data => {
            setTags(data['tags']);
        })
        .catch(error => {
            console.log("[ALL TAGS] " + error);
        });
    }

    const handleConfirmation = (response) => {
        document.getElementsByClassName("milestoneModal")[0].style.overflow = "auto";
        if (response === true) {
            if (verifyModalType === "delete") {
                deleteMilestone();
            }
            else if (verifyModalType === "save") {
                saveSelectedMilestone();
            }
            else if (verifyModalType === "complete") {
                completeMilestone();
            }
            else if (verifyModalType === "uncomplete") {
                uncompleteMilestone();
            }
        }
    };

    useEffect(() => {
        setProjectTaskLists([]);
        setPeopleData([]);
        setSelectedTags([]);
        if (!selectedMilestone) return;

        setDescription(selectedMilestone['description']);
        setTitle(selectedMilestone['title']);
        setPrivate(selectedMilestone['private'] === true ? true : false);
        setReminder(selectedMilestone['reminder'] === "no" ? false : true);
        setDeadline(formatDate(selectedMilestone['deadline']));
        GrabPeopleInfo(selectedMilestone);
        GrabProjectTasklists(selectedMilestone['project-id']);
        GrabAllTags();


        const responsiblePartyIds = selectedMilestone['responsible-party-ids'] ? selectedMilestone['responsible-party-ids'].split(',') : [];
        setSelectedPeople(responsiblePartyIds);

        const taskList = selectedMilestone['tasklists'] ? selectedMilestone['tasklists'] : [];
        if (taskList.length > 0) {
            const tasklistIds = taskList.map(tasklist => tasklist.id);
            setSelectedTasklist(tasklistIds);
        }

        if (selectedMilestone['tags']) {
            const taskTags = selectedMilestone['tags'];
            const tagIds = taskTags.map(tag => tag.id.toString());

            setSelectedTags(tagIds);    
        }

    }, [selectedMilestone]);


    const formatDate = (dateString) => {
        if (!dateString || dateString === null || dateString === "") {
            return "No date";
        }
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);

        const date = `${year}-${month}-${day}`;
        return date;
    };

    const GrabPeopleInfo = async (selectedTask) => {
        if (selectedTask['project-id']) {
            fetch('/api/teamwork/project', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    'project-id': selectedTask['project-id']
                })
            })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else
                {
                    console.log("[PEOPLE INFO] Error getting people info");
                }
            })
            .then(data => {
                // console.log(data);
                setPeopleData(data['people']);
                // console.log(data['people']);
            })
            .catch(error => {
                console.log("[PEOPLE INFO] " + error);
            });
        }
    }

    const GrabProjectTasklists = async (project_id) => {
        if (project_id) {
            fetch('/api/teamwork/project_task_list', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ project_id: project_id })
            })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else
                {
                    console.log("[PROJECT TASKLISTS] Error getting project tasklists");
                }
            })
            .then(data => {
                setProjectTaskLists(data['tasklists']);
            })
            .catch(error => {
                console.log("[PROJECT TASKLISTS] " + error);
            });
        }
    }

    const handleCheckboxChange1 = (e, person) => {
        if (e.target.checked === true) {
            if (!selectedPeople.includes(person)) {
                setSelectedPeople([...selectedPeople, person]);
            }
        } else {
            setSelectedPeople(selectedPeople.filter(p => p !== person));
        }
    }

    const handleCheckboxChange2 = (e, tasklist) => {
        if (e.target.checked === true) {
            if (!selectedTasklist.includes(tasklist)) {
                setSelectedTasklist([...selectedTasklist, tasklist]);
            }
        } else {
            setSelectedTasklist(selectedTasklist.filter(p => p !== tasklist));
        }
    }

    const deleteMilestone = async () => {
        const e = document.getElementById("milestone_status_handler");

        fetch('/api/teamwork/delete_milestone', {
            method: 'DELETE',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ milestone_id: selectedMilestone['id'] })
        })
        .then(response => {
            if (response.status === 200) {
                statusHandler(e, "Mijlpaal is zojuist verwijderd!", "green", false);
            }
            else
            {
                statusHandler(e, "Mijlpaal kon niet worden verwijderd!", "red");
            }
        })
        .then(data => {
            // console.log(data);
        })
        .catch(error => {
            console.log("[DELETE MILESTONE] " + error);
            statusHandler(e, "Mijlpaal kon niet worden verwijderd!", "red");
        });
    }

    const completeMilestone = async () => {
        const e = document.getElementById("milestone_status_handler");

        fetch('/api/teamwork/milestone/completion', {
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ type: "complete", milestone_id: selectedMilestone['id'] })
        })
        .then(response => {
            if (response.status === 200) {
                statusHandler(e, "Mijlpaal is zojuist afgerond!", "green", false);
            }
            else
            {
                statusHandler(e, "Mijlpaal kon niet worden afgerond!", "red");
            }
        })
        .then(data => {
            // console.log(data);
        })
        .catch(error => {
            console.log("[COMPLETE MILESTONE] " + error);
            statusHandler(e, "Mijlpaal kon niet worden afgerond!", "red");
        });
    }

    const uncompleteMilestone = async () => {
        const e = document.getElementById("milestone_status_handler");

        fetch('/api/teamwork/milestone/completion', {
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ type: "uncomplete", milestone_id: selectedMilestone['id'] })
        })
        .then(response => {
            if (response.status === 200) {
                statusHandler(e, "Mijlpaal is zojuist heropend!", "green", false);
            }
            else
            {
                statusHandler(e, "Mijlpaal kon niet worden heropend!", "red");
            }
        })
        .then(data => {
            // console.log(data);
        })
        .catch(error => {
            console.log("[COMPLETE MILESTONE] " + error);
            statusHandler(e, "Mijlpaal kon niet worden heropend!", "red");
        });
    }

    const saveSelectedMilestone = async () => {
        const e = document.getElementById("milestone_status_handler");

        fetch('/api/teamwork/update_milestone', {
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                milestone_id: selectedMilestone['id'],
                milestone_title: title,
                milestone_description: description,
                milestone_reminder: reminder,
                milestone_deadline: deadline,
                milestone_private: privateMilestone,
                milestone_responsible_party_ids: selectedPeople.join(','),
                milestone_tasklist_ids: selectedTasklist,
                milestone_tag_ids: selectedTags.join(','),
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data) {
                statusHandler(e, "Mijlpaal is zojuist opgeslagen!", "green", false);
            }
            else
            {
                statusHandler(e, "Mijlpaal kon niet worden opgeslagen!", "red");
            }
        })
        .catch(error => {
            console.log("[SAVE TEAMWORK TASK] " + error);
            statusHandler(e, "Mijlpaal kon niet worden opgeslagen!", "red");
        });
    }

    function redirectToTeamwork() {
        window.open(`https://portal.pixeldeluxe.nl/app/milestones/${selectedMilestone['id']}`);
    }

    function closeModal(e) {
        if (!e || e.id === "modal") {
            const modal = document.querySelector(".milestoneModal");
            modal.classList.add("disappear");

            document.body.style.overflow = "auto";

            modal.addEventListener('animationend', (e) => {
                if (e.animationName === 'disappear' && e.target.id === "modal") {
                    modal.style.display = "none";
                    modal.style.visibility = "hidden";
                    modal.classList.remove('disappear');
                }
            });
        }
    }

    function openVerifyModal(type) {
        setVerifyModalType(type);
        const modal = document.getElementById("verifyMilestoneModal");
        modal.style.display = 'flex';
        modal.style.visibility = 'visible';
        modal.classList.add("appear");

        if (document.getElementsByClassName("milestoneModal")[0]) {
            modal.scrollIntoView({ behavior: "instant", block: "center" });
            document.getElementsByClassName("milestoneModal")[0].style.overflow = "hidden";
        }

        modal.addEventListener('animationend', (e) => {
            if (e.animationName === 'appear' && e.target.id === modal.id) {
                modal.classList.remove('appear');
            }
        });
    }

    return (
        <>
            {selectedMilestone && (
                <div id="modal" className="milestoneModal" onClick={e => closeModal(e.target)}>
                    <VerifyModal handleConfirmation={handleConfirmation} type={verifyModalType} id="verifyMilestoneModal"/>
                    <div className="milestoneModal_holder">
                        <div className="modal_header">
                            <p className="heading4">Mijlpaal gegevens</p>
                            <img src={Cancel} alt="cancelImg" className="close_modal_button" onClick={e => closeModal()}></img>
                        </div>
                        <div className="modal_body">
                            <div className="modal_side">
                                <div className="modal_textarea_holder">
                                    <p>Beschrijving</p>
                                    <textarea className="modal_textarea" value={description} placeholder="Beschrijving van mijlpaal" onChange={e => setDescription(e.target.value)}></textarea>
                                </div>
                                <div className="milestoneModal_lists_holder">
                                    <div className="modal_list_holder">
                                        <p>Lijst</p>
                                        <div className="list_body milestoneModal_list">
                                            {projectTaskLists.length > 0 ? (
                                                projectTaskLists.map((tasklist, index) =>
                                                    <div className="switch_holder" key={index}>
                                                        <label className="switch">
                                                            <input type="checkbox" onChange={e => handleCheckboxChange2(e, tasklist['id'])} checked={selectedTasklist.includes(tasklist['id'])}></input>
                                                            <span className="slider round"></span>
                                                        </label>
                                                        <p className="switch_text">{tasklist['name']}</p>
                                                    </div>
                                                )
                                            )
                                            :
                                            (
                                                <p>Geen lijsten beschikbaar</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="modal_list_holder">
                                        <p>Verantwoordelijk</p>
                                        <div className="list_body milestoneModal_list">
                                            {peopleData.length > 0 ? (
                                                peopleData.map((person, index) =>
                                                    <div className="switch_holder" key={index}>
                                                        <label className="switch">
                                                            <input type="checkbox" onChange={e => handleCheckboxChange1(e, person["id"])} checked={selectedPeople.includes(person["id"])}></input>
                                                            <span className="slider round"></span>
                                                        </label>
                                                        <p className="switch_text">{person['firstName']} {person['lastName']}</p>
                                                    </div>
                                                )
                                            )
                                            :
                                            (
                                                <p>Niemand beschikbaar</p>
                                            )}
                                        </div>
                                    </div>
                                    <div class="modal_list_holder">
                                        <p>Tags</p>
                                        <div class="list_body milestoneModal_list">
                                            {tags.length > 0 ? (
                                                tags.map((tag, index) =>
                                                    <div class="switch_holder" key={index}>
                                                        <label class="switch">
                                                            <input type="checkbox" onChange={e => handleCheckboxChange3(e, tag['id'])} checked={selectedTags.includes(tag["id"])}></input>
                                                            <span class="slider round"></span>
                                                        </label>
                                                        <p class="switch_text tags" style={{backgroundColor: tag['color'], color: "white"}}>{tag['name']}</p>
                                                    </div>
                                                )
                                            )
                                            :
                                            (
                                                <p>Geen tags beschikbaar</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal_side space_between">
                                <div className="modal_item">
                                    <p>Titel</p>
                                    <input type="text" className="modal_input" placeholder="Titel van mijlpaal" value={title} onChange={e => setTitle(e.target.value)}></input>
                                </div>
                                <div className="modal_item">
                                    <p>Status</p>
                                    <select className="modal_select" value={selectedMilestone['status']} disabled={true}>
                                        <option value="all">Allemaal</option>
                                        <option value="new">Nieuw</option>
                                        <option value="reopened">Heropend</option>
                                        <option value="completed">Afgemaakt</option>
                                        <option value="deleted">Verwijdert</option>
                                        <option value="pending">In behandeling</option>
                                        <option value="incomplete">Niet afgemaakt</option>
                                        <option value="late">Laat</option>
                                        <option value="today">Vandaag</option>
                                        <option value="upcoming">Opkomend</option>
                                    </select>
                                </div>
                                <div className="modal_item">
                                    <div className="switch_holder">
                                        <label className="switch">
                                            <input type="checkbox" checked={privateMilestone} onChange={e => setPrivate(e.target.checked)}></input>
                                            <span className="slider round"></span>
                                        </label>
                                        <p className="list_item_text">Afgeschermd</p>
                                    </div>  
                                    <div className="switch_holder">
                                        <label className="switch">
                                            <input type="checkbox" checked={reminder} onChange={e => setReminder(e.target.checked)}></input>
                                            <span className="slider round"></span>
                                        </label>
                                        <p className="list_item_text">Herinnering</p>
                                    </div>
                                </div>
                                <div className="modal_item">
                                    <p>Deadline</p>
                                    <input type="date" className="modal_datepicker" value={deadline} onChange={e => setDeadline(e.target.value)}></input>
                                </div>
                                <div className="modal_buttons_holder">
                                    <button className="modal_button_warning" onClick={e => openVerifyModal("delete")}>Verwijderen</button>
                                    <button className="modal_button" onClick={e => openVerifyModal("complete")}>Afronden</button>
                                    <button className="modal_button" onClick={e => openVerifyModal("uncomplete")}>Heropenen</button>
                                    <button className="modal_button" onClick={e => redirectToTeamwork()}>Ga naar mijlpaal</button>
                                </div>
                                
                            </div>
                        </div>
                        <div className="modal_bottom_buttons">
                            <div id="milestone_status_handler" className="modal_status_handler"></div>
                            <button className="frame_button" onClick={() => openVerifyModal("save")}>Opslaan</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default MilestoneModal;
