import React, { useState, useEffect } from "react";

import '../css/main.css';

import Cancel from '../images/Cancel.png';

import VerifyModal from "./verifyModal";

import { statusHandler } from './frontendUtils';

function CreateTaskModal() {
    const [content, setContent] = useState("");
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState("none");
    const [dueDate, setDueDate] = useState("");
    const [startDate, setStartDate] = useState("");
    const [isCompleted, setIsCompleted] = useState(false);
    const [estimatedMinutes, setEstimatedMinutes] = useState(0);

    const [peopleData, setPeopleData] = useState([]);
    const [selectedPeople, setSelectedPeople] = useState([]);
    const [projectTaskLists, setProjectTaskLists] = useState([]);
    const [selectedTasklist, setSelectedTasklist] = useState("");

    const [teamworkProjectsData, setTeamworkProjectsData] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState("");

    const [verifyModalType, setVerifyModalType] = useState("save");
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const handleConfirmation = (response) => {
        document.getElementById("CreateTaskModal").style.overflow = "auto";
        if (response === true) {
            if (verifyModalType === "create") {
                createTask();
            }
        }
    };

    
    const GrabTeamworkProjects = async () => {
        fetch('/api/teamwork/projects', {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(response => response.json())
        .then(data => {
            if (data["projects"])
            {
                setTeamworkProjectsData(data["projects"]);
            }
        })
        .catch(error => {
            console.log("[TEAMWORK GRAB API] " + error);
        });
    }

    const GrabPeopleInfo = async (project_id) => {
        fetch('/api/teamwork/project', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'project-id': project_id
            })
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[PEOPLE INFO] Error getting people info");
            }
        })
        .then(data => {
            // console.log(data);
            setPeopleData(data['people']);
            // console.log(data['people']);
        })
        .catch(error => {
            console.log("[PEOPLE INFO] " + error);
        });
    }

    const GrabProjectTasklists = async (project_id) => {
        if (project_id) {
            fetch('/api/teamwork/project_task_list', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ project_id: project_id })
            })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else
                {
                    console.log("[PROJECT TASKLISTS] Error getting project tasklists");
                }
            })
            .then(data => {
                setProjectTaskLists(data['tasklists']);
            })
            .catch(error => {
                console.log("[PROJECT TASKLISTS] " + error);
            });
        }
    }

    const handleCheckboxChange1 = (e, person) => {
        if (e.target.checked === true) {
            if (!selectedPeople.includes(person)) {
                setSelectedPeople([...selectedPeople, person]);
            }
        } else {
            setSelectedPeople(selectedPeople.filter(p => p !== person));
        }
    }

    const handleCheckboxChange2 = (e, tag) => {
        if (e.target.checked === true) {
            if (!selectedTags.includes(tag)) {
                setSelectedTags([...selectedTags, tag]);
            }
        } else {
            setSelectedTags(selectedTags.filter(p => p !== tag));
        }
    }

    const GrabAllTags = async () => {
        fetch('/api/teamwork/tags', {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                console.log("[ALL TAGS] Error getting all tags");
            }
        })
        .then(data => {
            setTags(data['tags']);
        })
        .catch(error => {
            console.log("[ALL TAGS] " + error);
        });
    }

    function closeModal(e) {
        if (!e || e.id === "CreateTaskModal") {
            const modal = document.getElementById("CreateTaskModal");
            modal.classList.add("disappear");

            document.body.style.overflow = "auto";

            modal.addEventListener('animationend', (e) => {
                if (e.animationName === 'disappear' && e.target.id === "CreateTaskModal") {
                    modal.style.display = "none";
                    modal.style.visibility = "hidden";
                    modal.classList.remove('disappear');
                }
            });

            setProjectTaskLists([]);
            setPeopleData([]);
            setSelectedPeople([]);
            setSelectedProjectId("");
            setSelectedTasklist("");
            setContent("");
            setDescription("");
            setPriority("none");
            setDueDate("");
            setStartDate("");
            setIsCompleted(false);
            setEstimatedMinutes(0);
            setSelectedTags([]);
        }
    }

    const createTask = async () => {
        const e = document.getElementById("create_task_status_handler");

        if (selectedProjectId === "" || selectedTasklist === "" || selectedPeople.length === 0 || content === "") {
            e.style.visibility = "visible";
            e.style.color = "red";
            statusHandler(e, "Vul alle velden in!", "red");

            if (selectedPeople.length === 0) {
                statusHandler(e, "Selecteer minimaal 1 persoon!", "red");
            }

            if (selectedTasklist === "") {
                statusHandler(e, "Selecteer een lijst!", "red");
            }

            if (selectedProjectId === "") {
                statusHandler(e, "Selecteer een project!", "red")
            }

            if (content === "") {
                statusHandler(e, "Vul een titel in!", "red");
            }
            return;
        }

        fetch('/api/teamwork/create_task', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                task_content: content,
                task_description: description,
                task_priority: priority,
                task_due_date: dueDate,
                task_start_date: startDate,
                task_is_completed: isCompleted,
                task_estimated_minutes: estimatedMinutes,
                task_responsible_party_ids: selectedPeople.join(','),
                task_tasklist_id: selectedTasklist,
                task_tags: selectedTags.join(','),
            })
        })
        .then(response => {
            if (response.status === 201) {
                statusHandler(e, "Taak is aangemaakt!", "green", false);
            }
            else
            {
                statusHandler(e, "Taak kon niet aangemaakt worden!", "red")
            }
        })
        .catch(error => {
            console.log("[SAVE TASK] " + error);
            statusHandler(e, "Taak kon niet aangemaakt worden!", "red")
        });
    }

    useEffect(() => {
        GrabTeamworkProjects();

        const teamworkProjectsInterval = setInterval(GrabTeamworkProjects, 15000);

        return () => {
            clearInterval(teamworkProjectsInterval);
        };
    }, []);

    useEffect(() => {
        GrabAllTags();
        if (selectedProjectId) {
            GrabProjectTasklists(selectedProjectId);
            GrabPeopleInfo(selectedProjectId);
        }
    }, [selectedProjectId]);

    function openVerifyModal(type) {
        setVerifyModalType(type);
        const modal = document.getElementById("verifyCreateTaskModal");
        modal.style.display = 'flex';
        modal.style.visibility = 'visible';
        modal.classList.add("appear");

        if (document.getElementById("CreateTaskModal")) {
            modal.scrollIntoView({ behavior: "instant", block: "center" });
            document.getElementById("CreateTaskModal").style.overflow = "hidden";
        }

        modal.addEventListener('animationend', (e) => {
            if (e.animationName === 'appear' && e.target.id === modal.id) {
                modal.classList.remove('appear');
            }
        });
    }

    const getEstimateTaskDuration = async () => {
        const e = document.getElementById("create_task_status_handler");

        if (content.length === 0) {
            statusHandler(e, "Vul een titel in!", "red");
            return;
        }

        fetch('/api/assistant/estimate_task_time_priority', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                'task_name': content,
                'task_description': description,
                'task_due_date': dueDate,
                'task_start_date': startDate,
            }),
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else
            {
                statusHandler(e, "AI kon de taak niet berekenen!", "red");
            }
        })
        .then(data => {
            if (data) {
                setPriority(data['priority']);
                setEstimatedMinutes(data['time']);
                statusHandler(e, "AI heeft de taak berekend!", "green", false);
            }
            else
            {
                statusHandler(e, "AI kon de taak niet berekenen!", "red");
            }
        })
        .catch(error => {
            console.log("[OPENAI] " + error);
        });
    }

    return (
        <>
            <div id="CreateTaskModal" className="CreateModal" onClick={e => closeModal(e.target)}>
                <VerifyModal handleConfirmation={handleConfirmation} type={verifyModalType} id="verifyCreateTaskModal"/>
                <div className="CreateModal_holder">
                    <div className="modal_header">
                        <p className="heading4">Taak aanmaken</p>
                        <img src={Cancel} alt="cancelImg" className="close_modal_button" onClick={e => closeModal()}></img>
                    </div>
                    <div className="modal_body">
                        <div className="modal_side">
                            <div className="modal_topside">
                                <div className="modal_textarea_holder modal_topside_left">
                                    <p>Beschrijving</p>
                                    <textarea className="modal_topside_textarea" value={description} placeholder="Beschrijving van taak" onChange={e => setDescription(e.target.value)}></textarea>
                                </div>
                                <div className="modal_topside_right">
                                    <div className="modal_item">
                                        <p>Titel</p>
                                        <input type="text" className="modal_input" placeholder="Titel van taak" value={content} onChange={e => setContent(e.target.value)} required={true}></input>
                                    </div>
                                    <div className="modal_item">
                                        <p>Start datum</p>
                                        <input type="date" className="modal_datepicker" value={startDate} onChange={e => setStartDate(e.target.value)}></input>
                                    </div>
                                    <div className="modal_item">
                                        <p>Eind datum</p>
                                        <input type="date" className="modal_datepicker" value={dueDate} onChange={e => setDueDate(e.target.value)}></input>
                                    </div>
                                    <div className="modal_item">
                                        <p>Prioriteit</p>
                                        <select className="modal_select" value={priority} onChange={e => setPriority(e.target.value)} required={true}>
                                            <option value="none">Geen</option>
                                            <option value="low">Laag</option>
                                            <option value="medium">Medium</option>
                                            <option value="high">Hoog</option>
                                        </select>
                                    </div>
                                    <div className="modal_item">
                                        <p>Tijdsduur</p>
                                        <input className="modal_number" type="number" min="0" max="480" value={estimatedMinutes} onChange={e => setEstimatedMinutes(e.target.value)}></input>
                                    </div>
                                    <button className="modal_button" onClick={e => getEstimateTaskDuration()}>A.I. berekening</button>
                                </div>
                            </div>
                            <div className="milestoneModal_lists_holder">
                                <div className="modal_list_holder">
                                    <p>Projecten</p>
                                    <div className="list_body milestoneModal_list">
                                        {teamworkProjectsData.length > 0 ? (
                                            teamworkProjectsData.map((project, index) =>
                                                <div className="switch_holder" key={index}>
                                                    <label className="switch">
                                                        <input type="checkbox" onChange={e => setSelectedProjectId(project['id'])} checked={selectedProjectId === project['id']}></input>
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <p className="switch_text">{project['name']}</p>
                                                </div>
                                            )
                                        )
                                        :
                                        (
                                            <p>Geen projecten beschikbaar</p>
                                        )}
                                    </div>
                                </div>
                                <div className="modal_list_holder">
                                    <p>Lijst</p>
                                    <div className="list_body milestoneModal_list">
                                        {projectTaskLists.length > 0 ? (
                                            projectTaskLists.map((tasklist, index) =>
                                                <div className="switch_holder" key={index}>
                                                    <label className="switch">
                                                        <input type="checkbox" onChange={e => setSelectedTasklist(tasklist.id)} checked={selectedTasklist.toString() === tasklist.id.toString()}></input>
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <p className="switch_text">{tasklist['name']}</p>
                                                </div>
                                            )
                                        )
                                        :
                                        (
                                            <p>Geen lijsten beschikbaar</p>
                                        )}
                                    </div>
                                </div>
                                <div className="modal_list_holder">
                                    <p>Verantwoordelijk</p>
                                    <div className="list_body milestoneModal_list">
                                        {peopleData.length > 0 ? (
                                            peopleData.map((person, index) =>
                                                <div className="switch_holder" key={index}>
                                                    <label className="switch">
                                                        <input type="checkbox" onChange={e => handleCheckboxChange1(e, person["id"])} checked={selectedPeople.includes(person["id"])}></input>
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <p className="switch_text">{person['firstName']} {person['lastName']}</p>
                                                </div>
                                            )
                                        )
                                        :
                                        (
                                            <p>Niemand beschikbaar</p>
                                        )}
                                    </div>
                                </div>
                                <div class="modal_list_holder">
                                    <p>Tags</p>
                                    <div class="list_body milestoneModal_list">
                                        {tags.length > 0 ? (
                                            tags.map((tag, index) =>
                                                <div class="switch_holder" key={index}>
                                                    <label class="switch">
                                                        <input type="checkbox" onChange={e => handleCheckboxChange2(e, tag['id'])} checked={selectedTags.includes(tag["id"])}></input>
                                                        <span class="slider round"></span>
                                                    </label>
                                                    <p class="switch_text tags" style={{backgroundColor: tag['color'], color: "white"}}>{tag['name']}</p>
                                                </div>
                                            )
                                        )
                                        :
                                        (
                                            <p>Geen tags beschikbaar</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal_bottom_buttons">
                                <div id="create_task_status_handler" className="modal_status_handler"></div>
                                <button className="frame_button" onClick={e => openVerifyModal("create")}>Aanmaken</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateTaskModal;
