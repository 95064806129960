import React, { useState } from "react";
import {Helmet} from "react-helmet";

import '../css/forgotPassword.css';
import '../css/main.css'

import Nav from './nav';

import { statusHandler } from './frontendUtils';

function ForgotPassword() {
    const [email, setEmail] = useState('');

    const sendForgotPassword = async (e) => {
        const status_handler = document.getElementById("forgotpassword_status_handler");
        if (email.length > 0) {
            e.preventDefault();
            fetch('/api/forgot_password', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ email: email })
            })
            .then(response => {
                if (response.status === 500) {
                    statusHandler(status_handler, "Email is niet van PixelDeluxe!", "red");
                }
                else if (response.status === 200) {
                    statusHandler(status_handler, "Link is verstuurd naar E-mailadres!", "green", false);
                    return response.json();
                }
                else if (response.status === 404) {
                    statusHandler(status_handler, "E-mailadres bestaat niet in de database!", "red");
                }
                else
                {
                    statusHandler(status_handler, "Kon geen mail versturen naar E-mailadres!", "red");
                }
            })
            .then(data => {
                // console.log(data);
            })
            .catch(error => {
                console.log("[sendForgotPassword] " + error);
                statusHandler(status_handler, "Kon geen mail versturen naar E-mailadres!", "red");
            });
        }
    }

    return (
        <html>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Personal Assistant - Wachtwoord vergeten</title>
            </Helmet>
            <body>
                <Nav/>
                <div className="frame_holder">
                    <div className="frame">
                        <p className="heading4">Wachtwoord vergeten</p>
                        <form className="frame_body">
                            <p id="forgotpassword_status_handler" className="forgotpassword_status_handler"></p>
                            <div className="input_field">
                                <p className="input_caption">E-mailadres</p>
                                <input type="email" placeholder="Uw e-mailadres" className="button-radius" value={email} onChange={e => setEmail(e.target.value)} required={true}></input>
                            </div>
                            <div className="frame_bottom">
                                <button className="frame_button" type="submit" onClick={e => sendForgotPassword(e)}>Versturen</button>
                                <div className="frame_sidebuttons">
                                    <button type="button" className="frame_sidebutton" onClick={e => window.location.href = "/login"}>Ik weet mijn wachtwoord wel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </body>
        </html>
    );
}

export default ForgotPassword;
