export const setLoggedIn = (value) => {
    localStorage.setItem('loggedIn', value);
}

export const setUserId = (value) => {
    localStorage.setItem('userId', value);
}

export const setUserEmail = (value) => {
    localStorage.setItem('userEmail', value);
}

export const getLoggedIn = () => {
    return JSON.parse(localStorage.getItem('loggedIn'));
}

export const getUserId = () => {
    return localStorage.getItem('userId');
}

export const getUserEmail = () => {
    return localStorage.getItem('userEmail');
}

export const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

export const getTodayTime = () => {
    const today = new Date();
    const hours = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

export const get_user = async () => {
    fetch('/api/get_user', {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    })
    .then(response => {
        if (response.status === 200) {
            return response.json();
        }
        else
        {
            window.location.href = "/login";
        }
    })
    .then(data => {
        if (data['user_id']) {
            setLoggedIn(true);
            setUserId(data['user_id']);
            setUserEmail(data['user_email']);
        }
        else {
            setLoggedIn(false);
            window.location.href = "/login";
        }
    })
    .catch(error => {
        console.log("[GET USER] " + error);
        setLoggedIn(false);
        window.location.href = "/login";
    });
}





export const formatTime = (dateTimeString) => {
    if (!dateTimeString || dateTimeString === null || dateTimeString === "") {
        return "No time";
    }
    const dateObject = new Date(dateTimeString);
    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};

export const formatGoogleDateTime = (dateTimeString) => {
    if (!dateTimeString || dateTimeString === null || dateTimeString === "") {
        return "No date";
    }
    const dateObject = new Date(dateTimeString);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); 
    const day = dateObject.getDate().toString().padStart(2, '0');
    return `${day}/${month}/${year}`;
};

export const formatGoogleDate = (dateString) => {
    if (!dateString || dateString === null || dateString === "") {
        return "No date";
    }
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    return `${day}/${month}/${year}`;
};


export const formatDateTeamwork = (dateString) => {
    if (!dateString || dateString === null || dateString === "") {
        return "No date";
    }
    let year = dateString.substring(0, 4);
    let month = dateString.substring(4, 6);
    let day = dateString.substring(6, 8);

    return `${day}/${month}/${year}`;
}

export const formatLocation = (location) => {
    if (location) {
        return location;
    }
    else {
        return "No location";
    }
};




// new

export function dateToText(inputDateStr) {
    const inputDate = new Date(inputDateStr.slice(0, 4), inputDateStr.slice(4, 6) - 1, inputDateStr.slice(6, 8));

    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const outputDateStr = inputDate.toLocaleDateString('nl-NL', options);

    return outputDateStr;
}

export function googleEventsDateToText(inputDateStr, type) {
    const [year, month, day] = inputDateStr.split('-').map(Number);
    const inputDate = new Date(year, month - 1, day);

    if (type === "dow") {
        // day of week
        return inputDate.toLocaleDateString('nl-NL', { weekday: 'short' }).toLowerCase().slice(0, 3);
    }
    else if (type === "dom") {
        // day of month
        return String(inputDate.getDate()).padStart(2, '0');
    }
}

export function googleEventsDateTimeToText(inputDateStr, type) {
    const datePart = inputDateStr.split('T')[0];

    const [year, month, day] = datePart.split('-').map(Number);
    const inputDate = new Date(year, month - 1, day);

    if (type === "dow") {
        // day of week
        return inputDate.toLocaleDateString('nl-NL', { weekday: 'short' }).toLowerCase().slice(0, 3);
    }
    else if (type === "dom") {
        // day of month
        return String(inputDate.getDate()).padStart(2, '0');
    }
}

export function dateToFullText(inputDateStr) {
    if (inputDateStr === "") {
        return "";
    }
    const year = inputDateStr.slice(0, 4);
    const month = inputDateStr.slice(4, 6);
    const day = inputDateStr.slice(6, 8);
    const inputDate = new Date(`${year}-${month}-${day}`);

    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long', 
        day: 'numeric'
    };

    const formattedDate = inputDate.toLocaleDateString('nl-NL', options);

    return formattedDate;
}

export function dateTimeToFullText(timestamp) {
    if (timestamp) {
        let date = new Date(timestamp);
        const options = {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone: 'UTC'
        };

        const localDateString = date.toLocaleDateString('nl-NL', options);

        return localDateString;
    }
}

export function getUserTimezone() {
    var userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return userTimezone;
}

export function statusHandler(e, text, color, shake=true, duration=3000) {
    const timeoutId = e.getAttribute('data-timeout-id');
    if (timeoutId) {
        clearTimeout(timeoutId);
        e.removeAttribute('data-timeout-id');
    }

    e.style.visibility = "visible";
    e.style.color = color;
    e.innerText = text;

    if (shake === true) {
        e.classList.add('shake');

        e.addEventListener('animationend', () => {
            e.classList.remove('shake');
        });
    }

    const newTimeoutId = setTimeout(() => {
        e.style.visibility = "hidden";
    }, duration);

    e.setAttribute('data-timeout-id', newTimeoutId);
}