import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";

import '../css/main.css';
import '../css/register.css';

import Nav from './nav';

import { statusHandler } from './frontendUtils';

function Register() {
    const [email, setEmail] = useState('');
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');

    const register = async (e) => {
        const status_handler = document.getElementById("register_status_handler");
        if (email !== "" && firstname !== "" && lastname !== "") {
            e.preventDefault();
            fetch('/api/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ email: email, firstname: firstname, lastname: lastname })
            })
            .then(response => {
                if (response.status === 409) {
                    statusHandler(status_handler, "Kon niet registreren! Account bestaat al.", "red");
                    return;
                }
                else if (response.status === 401) {
                    statusHandler(status_handler, "E-mailadres is niet van PixelDeluxe!", "red");
                    return;
                }
                else if (response.status === 201) {
                    statusHandler(status_handler, "Registreren successvol! Check uw e-mail om uw wachtwoord in te stellen.", "green", false);
                    return response.json();
                }
                else if (response.status === 500) {
                    statusHandler(status_handler, "Registreren deels successvol! Kon geen mail versturen naar E-mailadres!", "red");
                }
            })
            .then(data => {
                // console.log(data);
            })
            .catch(error => {
                console.log("[register] " + error);
                statusHandler(status_handler, "Kon niet registreren! Probeer het opnieuw.", "red");
            });
        }
    }

    return (
        <html>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Personal Assistant - Registratie</title>
            </Helmet>
            <body>
                <Nav/>
                <div className="frame_holder">
                    <div className="frame">
                        <p className="heading4">Registratie</p>
                        <form className="frame_body">
                            <p id="register_status_handler" className="register_status_handler"></p>
                            <div className="input_field">
                                <p className="input_caption">Voornaam</p>
                                <input type="text" placeholder="Uw voornaam" value={firstname} onChange={e => setFirstName(e.target.value)} required={true}></input>
                            </div>
                            
                            <div className="input_field">
                                <p className="input_caption">Achternaam</p>
                                <input type="text" placeholder="Uw tussenvoegsel en achternaam" value={lastname} onChange={e => setLastName(e.target.value)} required={true}></input>
                            </div>

                            <div className="input_field">
                                <p className="input_caption">E-mailadres</p>
                                <input type="email" placeholder="Uw e-mailadres" value={email} onChange={e => setEmail(e.target.value)} required={true}></input>
                            </div>

                            <div className="frame_bottom">
                                <button className="frame_button" type="submit" onClick={e => register(e)}>Registeren</button>
                                <div className="frame_sidebuttons">
                                    <button type="button" className="frame_sidebutton" onClick={e => window.location.href = "/login"}>Ik heb al een account</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </body>
        </html>
    );
}

export default Register;