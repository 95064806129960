import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Dashboard from './templates/dashboard';
import Login from './templates/login';
import ForgotPassword from './templates/forgotPassword';
import Register from './templates/register';
import Settings from './templates/settings';
import Logout from './templates/logout';
import Timebox from './templates/timebox';
import ErrorPage from './templates/404page';
import ChangePassword from './templates/changePassword';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/api/*" element={null} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/changepassword/*" element={<ChangePassword />} />
        <Route path="/timebox" element={<Timebox />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/404" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
